import React, {useEffect, useRef} from 'react';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { getBrowserInfo, isExtensionSupported, isMobilePlatform, removeServerStyles } from '../../utils';
import { LocalesProvider, localesFactory } from '../../utils/locales';
import { isValidWotTraceId, saveWotTraceIdConnectCookie, saveWotTraceIdConnectLocalItem, saveWotTraceIdCookie, saveWotTraceIdLocalItem, wotTraceIdConnectLocalItem, wotTraceIdLocalItem } from '../../utils/wotTraceId';
import { isExtensionInstalled } from '../../utils/isExtentionInstalled';
import { useDeviceInfo } from '../../hooks';
import { theme} from '../../layouts/theme';
import GrowthBookProvider from '../ABTesting/GrowthBook';
import { CookiesConsent } from '../CookiesConsent/CookiesConsent';

const App = ({ children }) => {
    const dispatch = useDispatch();
    const checkTimeOut = useRef(null);
    const {isMobile, isIos, isAndroid, browserName, isExtSupported, isExtInstalled, wotTraceId, wotTraceIdConnect, shouldShowCookiesConsent} = useDeviceInfo();

    useEffect(() => {
        removeServerStyles();
        const validateDeviceInfo = () => {
            const client = isMobilePlatform();
            const clientBrowserName = getBrowserInfo().name.toLowerCase();
            const clientIsExtSupported = isExtensionSupported();
            if (client.isAndroid !== isAndroid) {
                dispatch({ type: 'IS_ANDROID', data: client.isAndroid });
            }
            if (client.isIos !== isIos) {
                dispatch({ type: 'IS_IOS', data: client.isIos });
            }
            if (client.isMobile !== isMobile) {
                dispatch({ type: 'IS_MOBILE', data: client.isMobile });
            }
            if (clientBrowserName !== browserName) {
                dispatch({ type: 'BROWSER_NAME', data: clientBrowserName });
            }
            if (clientIsExtSupported !== isExtSupported) {
                dispatch({ type: 'IS_EXT_SUPPORTED', data: clientIsExtSupported });
            }
            if (clientIsExtSupported) {
                const checkIfExtInstalled = (attempt) => {
                    const clientIsExtInstalled = isExtensionInstalled();
                    if (clientIsExtInstalled) {
                        if (clientIsExtInstalled !== isExtInstalled) {
                            dispatch({ type: 'IS_EXT_INSTALLED', data: true });
                        }
                    } else {
                        if (attempt === 5) {
                            if (clientIsExtInstalled !== isExtInstalled) {
                                dispatch({ type: 'IS_EXT_INSTALLED', data: false });
                            }
                        } else {
                            checkTimeOut.current = setTimeout(checkIfExtInstalled, 500, attempt + 1);
                        }
                    }
                };
                checkIfExtInstalled(1);
                return ()=> clearTimeout(checkTimeOut.current);
            }
        };
        const syncWotTraceIdFormLocalStorageWithCookiesAndRedux = () => {
            const wotTraceIdFromLocalStorage = wotTraceIdLocalItem();
            if (!isValidWotTraceId(wotTraceIdFromLocalStorage)) {
                saveWotTraceIdLocalItem(wotTraceId);
                saveWotTraceIdConnectLocalItem(wotTraceIdConnect);
            } else if (wotTraceIdFromLocalStorage !== wotTraceId) {
                const wotTraceIdConnectFromLocalStorage = wotTraceIdConnectLocalItem();
                saveWotTraceIdCookie(wotTraceIdFromLocalStorage);
                saveWotTraceIdConnectCookie(wotTraceIdConnectFromLocalStorage);
                dispatch({ type: 'WOT_TRACE_ID', data: {
                        wotTraceId: wotTraceIdFromLocalStorage,
                        wotTraceIdConnect: wotTraceIdConnectFromLocalStorage
                    }});
            }
        };
        syncWotTraceIdFormLocalStorageWithCookiesAndRedux();
        return validateDeviceInfo();
    }, []);

    return (
        <>
            {children}
            {shouldShowCookiesConsent && <CookiesConsent/>}
        </>
    );
};

export const MainApp = ({ reduxStore, gbSSRData, locales, sheetsRegistry, children }) => {
    return (
        <ThemeProvider theme={theme}>
            <ReduxProvider store={reduxStore}>
                <GrowthBookProvider gbSSRData={gbSSRData}>
                    <LocalesProvider value={locales || localesFactory(window?.__WOT__?.locales)}>
                        <App>
                            {children}
                        </App>
                    </LocalesProvider>
                </GrowthBookProvider>
            </ReduxProvider>
        </ThemeProvider>
    );
};
