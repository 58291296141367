// @flow
import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import type Node from 'react';
import {useAuth, useDeviceInfo, useEnabledFeatureAnalytics, useFeatureIsOn} from '../../hooks';
import type {AnalyticsSender, EventParams} from '../../hooks/useInternalAnalytics';
import {AnalyticsProvider, getSegment} from '../../components/AnalyticsProvider/AnalyticsProvider';
import Header from '../../blocks/header';
import Footer from '../../blocks/footer';
import {FeaturesSection} from './FeaturesSection/FeaturesSection';
import {PremiumSection} from './PremiumSection/PremiumSection';
import ReviewsSection from './ReviewsSection/ReviewsSection';
import {StyledFooterSection, StyledHomepageContainer} from './StyledHomepage';
import {HistorySection} from './HistorySection';

const googleOAuthFullPageEvent: () => EventParams = {
    testGroup: 'google_history_oauth_full_page_flow',
    platform: 'homepage'
};

export default function HomePage(): Node {
    const {isMobile, lang} = useDeviceInfo();
    const auth = useAuth();
    const isGoogleOAuthFullPage = useFeatureIsOn('google-history-oauth-full-page-flow');
    const eventParams: EventParams = {...googleOAuthFullPageEvent, specificFields: {segment: getSegment(isGoogleOAuthFullPage)}};
    const sendAnalytics: AnalyticsSender = useEnabledFeatureAnalytics(eventParams);

    useEffect(() => {
        ReactGA.pageview('/');
        sendAnalytics({type: 'HOMEPAGE_VIEW'});
    }, []);

    return (
        <StyledHomepageContainer>
            <Header {...{isMobile, lang, user: auth}}/>
            <AnalyticsProvider initParams={eventParams}>
                <HistorySection/>
            </AnalyticsProvider>
            <FeaturesSection/>
            <PremiumSection/>
            <ReviewsSection/>
            <StyledFooterSection>
                <Footer {...{isMobile, lang}}/>
            </StyledFooterSection>
        </StyledHomepageContainer>
    );
};
