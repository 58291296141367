// import ReactGA from 'react-ga';

// import env from './env';
// import { isNode } from './isnode';
// import { makeid } from './index';

// let guid = 'server';

// if (!isNode()) {
//     if (!('path' in Event.prototype)) {
//         Object.defineProperty(Event.prototype, 'path', {
//             get: function () {
//                 let path = [];
//                 let currentElem = this.target;
//                 while (currentElem) {
//                     path.push(currentElem);
//                     currentElem = currentElem.parentElement;
//                 }
//                 if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
//                     path.push(document);
//                 }
//                 if (path.indexOf(window) === -1) {
//                     path.push(window);
//                 }
//                 return path;
//             },
//         });
//     }

//     guid = localStorage && localStorage.getItem('_guid');
//     if (!guid) {
//         guid = makeid();
//         localStorage.setItem('_guid', guid);
//     }
// }

// ReactGA.initialize('UA-2412412-1', {
//     debug: env('ENV') !== 'production',
//     gaOptions: {
//         userId: guid,
//     },
// });


// export function tagManager(config) {
//     if (config.clicks && config.clicks.length > 0) {
//         document.addEventListener('click', (e) => {
//             for (let nod of e.path) {
//                 for (let option of config.clicks) {
//                     let flag = false;
//                     if (typeof option.id === 'object') {
//                         flag = option.id.test(nod.id);
//                     } else {
//                         flag = nod.id === option.id;
//                     }
//                     if (flag) {
//                         let category = nod.dataset.gaCategory || option.args.category;
//                         let action = nod.dataset.gaAction || option.args.action;
//                         let label = nod.dataset.gaLabel || option.args.label;
//                         let value = nod.dataset.gaValue || option.args.value;

//                         ReactGA.event({ category, action, label, value });
//                     }
//                 }
//             }
//         });
//     }
// }

// export default ReactGA;

export default {
    pageview() {},
    event() {}
}
