// @flow
import React, {useCallback, useContext, useState} from 'react';
import type Node from 'react';
import { analyticsContext } from '../AnalyticsProvider/AnalyticsProvider';
import {ConnectWithGoogleContainer, GoogleIconContainer, GoogleIcon, Title, Description, Terms} from './StyledConnectWithGoogleButton';
import {useDeviceInfo, useEnabledFeatureAnalytics, useTranslate, useFeatureIsOn} from '../../hooks';
import WebHistoryModal from '../WebHistoryModal/WebHistoryModal';
import { ConnectWithGoogleHistoryProvider, useConnectWithGoogleHistoryLogin } from '../ConnectWithGoogleHistoryProvider';

const GoogleButtonContent = (): Node => {
    const {wotTraceIdConnect} = useDeviceInfo();
    const sendAnalytics = useEnabledFeatureAnalytics();
    const eventParams = JSON.stringify(useContext(analyticsContext));
    const [googleCode, setGoggleCode] = useState(null);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const isFullPageFlow = useFeatureIsOn('google-history-oauth-full-page-flow');

    const onRescanClick = useCallback(() => {
        if (isFullPageFlow) {
            location.href = `${location.origin}/have-i-been-compromised?state=${eventParams}`;
        } else {
            sendAnalytics({ type: 'RESCAN_WITH_GOOGLE' });
            setOpenHistoryModal(true);
        }
    }, []);

    const closeModal = useCallback(() => {
        setOpenHistoryModal(false);
    }, []);

    const onConnectSuccess = ({code}: Object) => {
        setGoggleCode(code);
        setOpenHistoryModal(true);
        sendAnalytics({ type: 'PERMISSION_GRANTED' });
    };
    const loginWithGoogle = useConnectWithGoogleHistoryLogin({
        onSuccess: onConnectSuccess
    });

    const connectWithGoogle = (): () => void => {
        sendAnalytics({ type: 'CONNECT_WITH_GOOGLE' });
        //We use setTimeout here because we redirect to a new page and we want the event to be fired before the redirect. 
        setTimeout(loginWithGoogle, 0);
    };

    return (<>
        <ConnectWithGoogleButtonUi onClick={wotTraceIdConnect ? onRescanClick: connectWithGoogle}/>
        {openHistoryModal && <WebHistoryModal googleCode={googleCode} onClose={closeModal} onConnectSuccess={onConnectSuccess}/>}
    </>);
};

export const ConnectWithGoogleButton = (): Node => {
    return (
        <ConnectWithGoogleHistoryProvider>
            <GoogleButtonContent/>
        </ConnectWithGoogleHistoryProvider>
    );
};

export const ConnectWithGoogleButtonUi = ({onClick}: {onClick?: Function}): Node => {
    const translate= useTranslate();
    const {wotTraceIdConnect} = useDeviceInfo();
    return (
        <ConnectWithGoogleContainer onClick={onClick}>
            <GoogleIconContainer>
                <GoogleIcon/>
            </GoogleIconContainer>
            <Title>{translate(`components.connectWithGoogle.${wotTraceIdConnect ? 'connected-' : ''}text`)}</Title>
        </ConnectWithGoogleContainer>
    );
};

export const ConnectWithGoogleTerms = (): Node => {
    const translate= useTranslate();
    const connectTranslate = (key: string): string => translate(`components.connectWithGoogle.${key}`);
    const {wotTraceIdConnect} = useDeviceInfo();
    return wotTraceIdConnect ? <></> : (
        <Description>
            {connectTranslate('legal')}
            <Terms href={'/terms'}>{connectTranslate('terms')}</Terms>
            {connectTranslate('and')}
            <Terms href={'/privacy'}>{connectTranslate('privacy')}</Terms>
        </Description>
    );
};

