// @flow
import * as React from 'react';
import classnames from 'classnames';
import throttle from 'lodash/throttle';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import ReactGA from '../../utils/ga.js';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import env from '../../utils/env';

type State = {
    isMount: boolean
}

type Props = {
    classes: Object,
    message: Object,
}

let gaEventIn = throttle((text: string): any => {
    ReactGA.event({ category: 'General', action: 'Snackbar showed', label: text });
}, 5000);

let gaEventOut = throttle((text: string): any => {
    ReactGA.event({ category: 'General', action: 'Snackbar closed', label: text });
}, 5000);

class Notification extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isMount: false,
        };
    }

    componentDidMount() {
        if (env('ENV') !== 'production') {
            window.snackbar = this;
        }
        this.setState({ isMount: true });
    }

    render(): any {
        let { message, classes } = this.props;
        let { isMount } = this.state;
        let color = '';

        if (!isMount
            || !message
            || typeof message.text !== 'string'
            || message.text === '{}'
            || message.text === '') {
            return <i/>;
        }

        switch (message.color) {
            case 'red':
                color = classes.error;
                break;
            case 'green':
                color = classes.success;
                break;
            default:
                color = classes.default;
                break;
        }

        let text = message.text ? JSON.stringify(message.text).replace(/"/g, '') : 'Unexpected error';

        // gaEventIn(text);

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={message.open}
                autoHideDuration={6000}
                onClose={message.onClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    'className': classnames(classes.content, color),
                }}
                className={classes.root}
                message={
                    <span id="message-id">
                        {text}
                    </span>
                }
                onExited={() => {
                    gaEventOut(text);
                }}
                onEntered={() => {
                    gaEventIn(text);
                }}
                action={[
                    <IconButton
                        id={'wot-snackbar-close'}
                        data-ga-label={text}
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={message.onClose}
                    >
                        <CloseIcon/>
                    </IconButton>,
                ]}
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(Notification);
