// @flow
import type Node from 'react';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useDeviceInfo, useTranslate, useFeatureIsOn} from '../../../hooks';
import {dispatchWotTraceIdConnect} from '../../../utils/wotTraceId';
import {FlexColumn} from '../../../StyledUtils';
import { Header, Subtitle, Title, ResultsSection, SafeDescription, RiskyDescription, FeaturesTitle, FeaturesDescription,
    FeatureRow, V, WarningIcon, DownloadSection, CtaContainer, CtaContainerIcon, CtaContainerDescription, CtaContainerText } from './StyledResultsPage';
import {SAFE, SUSPICIOUS, NOT_SAFE, MY_DASHBOARD_URL, WOT_PRODUCT} from '../../../utils/const';
import {CategoryResults} from './CategoryResults/CategoryResults';
import {InstallWOT} from '../../buttons/InstallWOT/InstallWOT';

export type WebHistoryCategoryResults = {
    total: number,
    sites: {
        [key: string]: SiteDetails
    }
}

export type SiteDetails = {
    visitCount: number,
    isAdult?: boolean
}

type WebHistoryResults = {
    [SAFE]: WebHistoryCategoryResults,
    [SUSPICIOUS]: WebHistoryCategoryResults,
    [NOT_SAFE]: WebHistoryCategoryResults,
}

type ResultsPageProps = {
    webHistoryResults: WebHistoryResults
}

const goToDashboardProps = {
    text: 'modals.webHistory.results.goToDashboard',
    link: `${MY_DASHBOARD_URL}?${WOT_PRODUCT}=web-history-results&skip_intro=true`
};

const installButtonFullPageCss = {
    padding: '8px 16px',
    fontSize: 14,
    fontWeight: 700,
    withoutIcon: true
};

const appsFlyerLink = 'https://wotemail.onelink.me/teed/jvopmhqm';

export const ResultsPage = ({webHistoryResults}: ResultsPageProps ): Node => {
    const dispatch = useDispatch();
    const {wotTraceIdConnect, isDashboardSupported, isMobile} = useDeviceInfo();
    const safeResults: WebHistoryCategoryResults = webHistoryResults[SAFE];
    const suspiciousResults: WebHistoryCategoryResults = webHistoryResults[SUSPICIOUS];
    const notSafeResults: WebHistoryCategoryResults = webHistoryResults[NOT_SAFE];
    const riskyResults = notSafeResults.total + suspiciousResults.total;
    const foundRiskyResults = riskyResults > 0;
    const highestCounter = riskyResults ? Math.max(notSafeResults.total, suspiciousResults.total): safeResults.total;
    const translate = useTranslate();
    const isFullPageFlow = useFeatureIsOn('google-history-oauth-full-page-flow');
    const resultsPageTranslate = (key: string): string => translate(`modals.webHistory.results.${key}`);
    const ctaSectionTranslate = (key: string): string => resultsPageTranslate(`cta-section.${key}`);

    useEffect(() => {
        if (!wotTraceIdConnect) {
            dispatchWotTraceIdConnect(true, dispatch);
            window.dataLayer && window.dataLayer.push({'event': 'permission_granted'});
        }
    }, []);


    const ctaStyle = !!notSafeResults.total ?
        {color: '#FF4747', background: '#FFE5E5', trustLevel: 'unsafe'}:
        !!suspiciousResults.total ?
            {color: '#F97207', background: '#FFF3E5', trustLevel: 'suspicious'}:
            {color: '#106B40', background: '#E8FDF3', trustLevel: 'safe'};

    return (<>
        <Header>
            <Title>{resultsPageTranslate('title')}</Title>
            <Subtitle>{resultsPageTranslate(`${foundRiskyResults ? 'risky-' : ''}subtitle`)}</Subtitle>
        </Header>
        <ResultsSection isFullPageFlow={isFullPageFlow}>
            {!foundRiskyResults && <CategoryResults color={'#16D478'} iconType={'safe'} results={safeResults} title={resultsPageTranslate('trusted-sites')} highestCounter={highestCounter}/>}
            {!!notSafeResults.total && <CategoryResults color={'#FF4747'} iconType={'unsafe'} results={notSafeResults} title={resultsPageTranslate('untrusted-sites')} highestCounter={highestCounter}/>}
            {!!suspiciousResults.total && <CategoryResults color={'#FFA033'} iconType={'suspicious'} results={suspiciousResults} title={resultsPageTranslate('suspicious-sites')} highestCounter={highestCounter}/>}
        </ResultsSection>
        { isFullPageFlow ? (
            <CtaContainer ctaStyle={ctaStyle}>
                <CtaContainerIcon ctaStyle={ctaStyle}/>
                <CtaContainerDescription>
                    {ctaSectionTranslate('title')}
                    <CtaContainerText>
                        {ctaSectionTranslate(`subtitle-${isMobile ? 'mobile' : 'desktop'}`)}
                    </CtaContainerText>
                </CtaContainerDescription>
                <InstallWOT appsFlyerLink={appsFlyerLink}
                            styleSheet={installButtonFullPageCss}
                            text={ctaSectionTranslate('cta')}
                            extInstalledOptions={isDashboardSupported && goToDashboardProps}/>
            </CtaContainer>
        ): <>
            {foundRiskyResults ?
                <RiskyDescription>
                    <WarningIcon/><span>{resultsPageTranslate('tip')}</span>
                </RiskyDescription> :
                <SafeDescription>
                    <FeaturesTitle>{resultsPageTranslate('featuresTitle')}</FeaturesTitle>
                    <FeaturesDescription>
                        <FlexColumn>
                            <FeatureRow><V/>{resultsPageTranslate('feature1')}</FeatureRow>
                            <FeatureRow><V/>{resultsPageTranslate('feature2')}</FeatureRow>
                            <FeatureRow><V/>{resultsPageTranslate('feature3')}</FeatureRow>
                        </FlexColumn>
                        <FlexColumn>
                            <FeatureRow><V/>{resultsPageTranslate('feature4')}</FeatureRow>
                            <FeatureRow><V/>{resultsPageTranslate('feature5')}</FeatureRow>
                            <FeatureRow><V/>{resultsPageTranslate('feature6')}</FeatureRow>
                        </FlexColumn>
                    </FeaturesDescription>
                </SafeDescription>
            }
            <DownloadSection>
                <InstallWOT appsFlyerLink={appsFlyerLink}
                            styleSheet={{fontWeight: 500}}
                            text={resultsPageTranslate('installExtension')}
                            extInstalledOptions={isDashboardSupported && goToDashboardProps}/>
            </DownloadSection>
        </>}
    </>);
};
