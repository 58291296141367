// @flow
import url from 'url';
import punycode from 'punycode';
import trim from 'lodash/trim';
import { XmlEntities as Entities } from 'html-entities';
import type { $Request, $Response } from 'express';
import { v4 as uuidv4 } from 'uuid';

const entities = new Entities();

import {
    URLS,
    REGEX,
    RX_EMAIL_VALIDATION,
    FACEBOOK_DIALOG_URL,
    FACEBOOK_SHARE_URL,
    TWITTER_DIALOG_URL,
    confidenceRanks,
    confidenceRanksCircles,
    SUB_TARGETS_ENABLED_LIST,
    URLS_WITH_NO_COOKIES_CONSENT,
    TOP_TLDS,
    UTM_VARIABLES, ONE_MINUTE_IN_MS,
    DASHBOARD_V2_SUPPORTED_BROWSERS,
    GOOGLE_PLAY_STORE,
    APP_STORE_UTM, validLanguages,
    COOKIE_MAXIMUM_LIFESPAN
} from './const';
import type {ITranslate} from './locales';
import { isNode } from '../utils/isnode';
import { read, write } from '../utils/cookie';
import env from './env';

const set = 'abcdefghijklmnopqrstuvwxyz234567';

export function stripTopTldsFromTarget(target: string): string {
    const topTld = TOP_TLDS.find((tld: string): boolean => target.endsWith(tld));
    if (topTld) {
        return target.replace(topTld, '');
    }
    return target;
}

export function getBasename(location: string,
                            options: { length: number, uid: boolean } = { uid: false, length: 1 }): string {
    let withoutModals = location.replace(URLS.EXCEPT_MODALS_REGEX, '');
    let match = withoutModals.match(URLS.FIRST_SECTION_REGEX);
    let section = match && match.length > 0 ? match[0] : '';
    let end = '';
    let start = '';

    if (options.uid) {
        let uid = location.match(/\/user(\/[\d]+)?/);
        end += uid && uid[1] ? uid[1] : '';
    }

    if (options.length && options.length >= 2) {
        let prev = section;
        for (let i = 0; i < options.length - 1; i++) {
            let rx = new RegExp(`${prev}(\/[a-zA-Z0-9\-\:]+)?`);
            let additionalMatch = location.match(rx);
            let additional = additionalMatch && additionalMatch[1] ? additionalMatch[1] : '';
            end += additional;
            prev = additional || prev;
        }
    }

    return start + (section) + end;
    // return match.length > 0 ? match[0] : "/";
}

export function timeout(ms: number, promise: Promise<any>): Promise<Function> {
    return new Promise((resolve: Function, reject: Function) => {
        setTimeout(() => {
            reject(new Error('request timeout'));
        }, ms);
        promise.then(resolve, reject);
    });
}

export function validateJSON(str: ?string): boolean {
    return !!str && /^[\],:{}\s]*$/
        .test(str.replace(/\\["\\\/bfnrtu]/g, '@')
            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
            .replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
}

type Validate = { message: string, status: boolean };

export function validatePassword(pass: string, translate: ITranslate): Validate {
    const res = REGEX.PASSWORD.test(String(pass).toLowerCase());
    return {
        status: res,
        message: res ? '' : (!pass ? translate('components.label.empty.field') : translate('components.label.pass.rules')),
    };
}

export function validateConfirmPassword(pass: string, confirmPass: string, translate: ITranslate): Validate {
    const res = pass === confirmPass;
    return {
        status: res,
        message: res ? '' :
            (!pass ? translate('components.label.empty.field') : translate('components.label.pass.mismatch')),
    };
}

export function validateEmail(email: string, translate: ITranslate): Validate {
    const re = RX_EMAIL_VALIDATION;
    const lengthLimit = email.length <= 50;
    const res = re.test(String(email).toLowerCase()) && lengthLimit;
    return {
        status: res,
        message: res ? '' : (!email ? translate('components.label.empty.field') : translate('components.label.incorrect.email')),
    };
}

export function toString(smth: any): string {
    if (typeof smth === 'string') {
        return smth;
    } else if (hasOwnProperty(smth, 'toString')) {
        return smth.toString();
    } else if (smth) {
        return ('' + smth);
    } else {
        return '';
    }
}

export function elipse(source: string, part1: number = 12, part2: number = 15): string {
    if (typeof source !== 'string') {
        return source;
    }
    if (source.length <= part1 + part2) {
        return source;
    }
    return source.slice(0, part1) + '...' + source.slice(source.length - part2);
}

export function extractDomain(url: string): string {
    let domain;

    if (url.indexOf('//') > -1) {
        domain = url.split('/')[2];
    } else {
        domain = url.split('/')[0];
    }

    domain = domain.split(':')[0];
    domain = domain.split('?')[0];

    return domain;
}

export function hasOwnProperty(obj: any, prop: string): boolean {
    let proto = obj.__proto__ || obj.constructor.prototype;
    return (prop in obj) && (!(prop in proto) || proto[prop] !== obj[prop]);
}

export function encodeTarget(input: string): string {
    try {
        const parsed = url.parse(input.match(/^https?:\/\//) ? input : `http://${input}`);
        if (!parsed.host || !parsed.pathname || parsed.pathname === '/') {
            return input;
        } else {
            const encoded = base32encode(trim(parsed.pathname, '/'));
            if (encoded === null) {
                return input;
            } else {
                return `_p_${encoded || ''}.${parsed.host || ''}`;
            }
        }
    } catch (e) {
        return input;
    }
}

export function decodeTarget(input: string): string {
    try {
        const utf = punycode.toUnicode(input);
        const [, payload, host] = /^_p_([a-z2-7]+)\.(.+)$/.exec(utf) || [];
        if (!payload || !host) {
            return utf;
        }
        let decoded = base32decode(payload);
        if (decoded === null) {
            return utf;
        }
        decoded = entities.encode(decoded);
        return `${host}/${decoded}`;
    } catch (e) {
        return input;
    }
}

export function normalizeTarget(req: Object): any {
    const indexOfScorecard = req.originalUrl.indexOf('/scorecard/') + 11;
    const scorecardTarget = req.originalUrl.substring(indexOfScorecard);
    const parsed = url.parse(scorecardTarget);
    const hostname = parsed.hostname || '';
    const pathname = parsed.pathname || '';
    const urlWithoutProtocol = `${hostname}${pathname}`;
    if (parsed.protocol) {
        return {target: urlWithoutProtocol};
    }
    try {
        decodeURI(urlWithoutProtocol);
    } catch {
        return {target: null};
    }
    return false;
}

export function isSubtargetEnabled(target: string): boolean {
    return SUB_TARGETS_ENABLED_LIST.indexOf(target) !== -1;
}

export function redirectTarget(target: string, req: $Request, res: $Response): boolean {
    if (!target) {
        res.redirect(301, `${req.protocol}://${req.hostname}/404`);
    } else {
        res.redirect(301, `${req.protocol}://${req.hostname}/scorecard/${target}`);
    }
    return true;
}

export function buildTarget(req: $Request): string {
    const shouldEnecodeTarget = isSubtargetEnabled(req.params.currentDomain.toLowerCase());
    const target = getTarget(req.path && shouldEnecodeTarget ? req.originalUrl.replace('/scorecard/', '') : req.params.currentDomain.toLowerCase());
    return target.endsWith('/') ? target.substr(0, target.length - 1) : target;
}

export function tounicode(target: string): string {
    if (!target) {
        return target;
    }
    let result = [];

    target.toLowerCase().split('.').forEach(function (part: string) {
        if (part.indexOf('xn--') === 0) {
            result.push(punycode.decode(
                part.replace('xn--', '')));
        } else {
            result.push(part);
        }
    });

    return result.join('.');
}

export function getBrowserInfo(req: ?$Request): { name: string, version: string } {
    let ua = getUserAgent(req);
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge|Edg|SamsungBrowser)\/(\d+)/);
        if (tem != null) {
            return { name: tem[1].replace('OPR', 'Opera').replace('Edg', 'Edge').replace('SamsungBrowser', 'Samsung'), version: tem[2] };
        }
    }
    if (M[2]) {
        M = [M[1], M[2]];
    } else {
        if (isNode()) {
            M = ['', ''];
        } else {
            M = [navigator.appName, navigator.appVersion, '-?'];
        }
    }
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem && tem[1]);
    }
    return { name: M[0], version: M[1] };
}

export function capitalizeString(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function getOS(req: ?$Request): ?string {
    const userAgent = getUserAgent(req);
    let os = null;

    const clientStrings = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Linux', r: /(Linux|X11)/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
    ];
    for (let cs of clientStrings) {
        if (cs.r.test(userAgent)) {
            os = cs.s;
            break;
        }
    }
    return os;
}

export function getUserAgent(req: ?$Request): string {
    if (isNode()) {
        return (req && req.get('User-Agent')) || '';
    }
    return navigator.userAgent;
}

export function isSupportedMobile(req: ?$Request): boolean {
    const os = getOS(req);
    return (os === 'Android' || os === 'iOS');
}

export function isMobilePlatform(req: ?$Request): Object {
    const os = getOS(req);
    const isAndroid = os === 'Android';
    const isIos = os === 'iOS';
    const isMobile = isAndroid || isIos;
    return {isAndroid, isIos, isMobile};
}

export function isExtensionSupported(req: ?$Request): boolean {
    const browser = getBrowserInfo(req);
    const browserName = browser.name.toLowerCase();
    return Boolean(browserName.match(/^(chrome$)|^(opera$)|^(yandex$)|^(firefox$)|^(edge$)|^(samsung$)/));
}

export function isOpera(): boolean {
    const browser = getBrowserInfo();
    const browserName = browser.name.toLowerCase();
    return browserName ==='opera';
}

export function isSafari(): boolean {
    const browser = getBrowserInfo();
    const browserName = browser.name.toLowerCase();
    return browserName ==='safari';
}

export function isiOS(): boolean {
    const os = isNode() ? '' : getOS();
    return os === 'iOS';
}

export function isAndroid(): boolean {
    const os = isNode() ? '' : getOS();
    return os === 'Android';
}

export function makeid(): string {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
}

export function getWotTraceId(req: ?$Request): string {
    const wotTraceId = isNode() && req ? req.wotTraceId : read('wotTraceId');
    if (wotTraceId) {
        return wotTraceId;
    }
    const newWotTraceId = `web-${uuidv4()}`;
    write('wotTraceId', newWotTraceId, `;maxAge=${COOKIE_MAXIMUM_LIFESPAN}`);
    return newWotTraceId;
}

export async function getUserPlan(uid: string, type: string, token: string, wotTraceId: string): Object {
    const userAPIRoot = env(['configuration', 'userApi']);
    try {
        const response = await fetch(`${userAPIRoot}/v3/user/plan?uid=${uid}&type=${type}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'wot-trace-id': wotTraceId
            },
        });
        const userPlan = await response.json();
        return userPlan;
    } catch (error) {
        console.log('getUserPlan - Could not get plan for user', error);
        return null;
    }
}

export function getTarget(s: string): string {
    return encodeTarget(punycode.toASCII(s));
}

export function getHostName(url: string): ?string {
    let match = url.match(/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
    } else {
        return null;
    }
}

export function getDomain(url: string): ?string {
    let hostName = getHostName(url);
    let domain = hostName;

    if (hostName != null) {
        let parts = hostName.split('.').reverse();

        if (parts != null && parts.length > 1) {
            domain = parts[1] + '.' + parts[0];

            if (hostName.toLowerCase().indexOf('.co.uk') !== -1 && parts.length > 2) {
                domain = parts[2] + '.' + domain;
            }
        }
    }

    return domain;
}

export function base32encode(s: string): ?string {
    try {
        let r = '';
        let b = 0;
        let l = 0;

        for (let i = 0; i < s.length; i += 1) {
            const n = s.charCodeAt(i);

            if (n > 255) {
                return null;
                /* Invalid input */
            }

            b = (b << 8) + n; // eslint-disable-line no-bitwise
            l += 8;

            do {
                l -= 5;
                r += set[(b >> l) & 0x1F]; // eslint-disable-line no-bitwise
            } while (l >= 5);
        }

        if (l > 0) {
            r += set[(b << (5 - l)) & 0x1F]; // eslint-disable-line no-bitwise
        }

        return r;
    } catch (e) {
        return null;
    }
}

export function base32decode(s: string): ?string {
    let rev = null;
    try {
        /* Build a reverse lookup table */
        if (!rev) {
            rev = {};

            for (let i = 0; i < set.length; i += 1) {
                rev[set.charAt(i)] = i;
            }
        }

        let r = '';
        let b = 0;
        let l = 0;

        for (let i = 0; i < s.length; i += 1) {
            const n = rev[s.charAt(i)];

            if (n === null) {
                return null;
                /* Invalid input */
            }

            b = (b << 5) + n; // eslint-disable-line no-bitwise
            l += 5;

            while (l >= 8) {
                l -= 8;
                r += String.fromCharCode((b >> l) & 0xFF); // eslint-disable-line no-bitwise
            }
        }

        if (l >= 5) {
            return null;
            /* Invalid input */
        }
        return r;
    } catch (e) {
        return null;
    }
}

export function getDate(date: Date): string {
    return `${date.toDateString().slice(4, 10)},${date.toDateString().slice(10)}`;
}

export function compareStrings(stringA: string, stringB: string): number {
    if (stringA > stringB) {
        return 1;
    } else if (stringA < stringB) {
        return -1;
    } else {
        return 0;
    }
}

export function getStatus(string: string): string {// GET LAST WORD IN _%_ STRING
    if (!string) {
        return '';
    } else {
        let res = string.match(/(\w+_)+(\w+)/);
        if (res) {
            return res[2];
        } else {
            return '';
        }
    }
}

export const getConfidenceRank = (score: number): string => score <= 10 ? confidenceRanks[0] : confidenceRanks[(Math.ceil(score / 20))];

export const getConfidenceRankCircles = (score: number): string => score <= 10 ? confidenceRanksCircles[0] : confidenceRanksCircles[(Math.ceil(score / 20))];

export const getShareWebsiteMsg = (target: string, rating: number | string, url: string, translate: ITranslate, msg: string): string =>
    translate(msg, { target, rating, url: url });

export function getFacebookSharingUrl(target: string, rating: number | string, url: string, translate: ITranslate, msg: string): string {
    const text = getShareWebsiteMsg(target, rating, url, translate, msg);

    return `${FACEBOOK_DIALOG_URL}link=${FACEBOOK_SHARE_URL}&description=${text}`;
}
export function getTwitterSharingUrl(target: string, rating: number | string, url: string, translate: ITranslate, msg: string): string {
    const text = getShareWebsiteMsg(target, rating, url, translate, msg);
    return `${ TWITTER_DIALOG_URL }&text=${ text }`;
}

export function openLoginPopup(): any {
    const loginBtn = document.getElementById('loginButton');
    const parentNode: any = loginBtn && loginBtn.parentNode;
    if (parentNode) {
        loginBtn.click();
    }
}

export function extractActualIp (req: $Request): string {
    return req.get('x-forwarded-for')
        ? req.get('x-forwarded-for').split(',')[0]
        : req.connection.remoteAddress;
}

export function getUtmString(search?: string): string {
    const params = new URLSearchParams(search || (!isNode() ? location.search : ''));
    const utmParams = [];
    UTM_VARIABLES.forEach((val: string) => {
        utmParams.push(val + '=' + (params.get(val) || ''));
    });
    return utmParams.join('&');
}

// We save utms to cookies ONLY to read them from the extension in the installation or to pass them in the url.
export function saveUtmToCookies() {
    const params = new URLSearchParams(!isNode() ? location.search : '');
    const domain = location.hostname.replace('www', '');
    let date = new Date();
    date.setTime(date.getTime() + (15 * ONE_MINUTE_IN_MS));
    UTM_VARIABLES.forEach((name: string) => {
        const val = params.get(name);
        val && write(`${name.replace('utm', 'wot')}`, val, `;Domain=${domain};expires=${date.toUTCString()}`);
    });
}

export function shouldHideCookieConsentInPage(page?: string): boolean {
    const path = isNode() ? page || '' : location.pathname;
    return !!URLS_WITH_NO_COOKIES_CONSENT.find((url: string): boolean => path.indexOf(url) !== -1);
}

export function isEmptyObject(obj: Object): boolean {
    if (!obj || typeof obj !== 'object') {
        return true;
    }
    return !(Object.keys(obj).length);
}

export function isDashboardSupported(userAgent?: string): boolean {
    const browserName = getBrowserInfo(userAgent).name.toLowerCase();
    return DASHBOARD_V2_SUPPORTED_BROWSERS.includes(browserName);
}

export function getStoreLink(product?: string): string {
    const googlePlayStore = GOOGLE_PLAY_STORE.concat(`&${getUtmString()}`);
    const appleAppStore = `${APP_STORE_UTM}&${getUtmString()}`;
    const os = isNode()? '' : getOS();
    let link = `${env(['configuration', 'mywotAppUrl'])}/chooseYourPlan?planType=extensionPremium&wot_product=${product}&${getUtmString()}`;
    if (os === 'iOS') {
        link = appleAppStore;
    } else if (os === 'Android') {
        link = googlePlayStore;
    }

    return link;
}

export function langPrefix(options?: {lang?: string, cookies?: any}): string {
    const language = (options && options.lang) || read('lang', options && options.cookies);
    return language && language.split('-')[0] === 'en' ? '': `/${language.split('-')[0]}`;
}

export function langInPathRegex(languagesRe: string): RegExp {
   return new RegExp(`^\/(?:${languagesRe})(?:$|\/)(.+)*`);
}

export function pathWithoutLang(path: string): string {
    return path.replace(langInPathRegex(validLanguages.join('|')), '/$1');
}

export function pathWithLang(path: string, options?: {lang?: string, cookies?: any}): string {
    return `${langPrefix(options)}${pathWithoutLang(path)}`;
}

export function isStagingEnv(): boolean {
    return isNode() ? process.env.NODE_ENV === 'development' : (document.location.host.includes('ninja') || document.location.host.includes('localhost'));
}

export const removeServerStyles = () => {
    const jssStyles = document.getElementById('jss');
    if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
    }
};

export const parseJson = (jsonString?: string): Object | undefined => {
    try {
        return JSON.parse(jsonString);
    } catch (e) {}
};

export const parseQueryObjectToString = (queryObject: Object): string => {
    let queryString = '';
    if (!isEmptyObject(queryObject)) {
        queryString += '?';
        for (const [key, value] of Object.entries(queryObject)) {
            queryString += `${key}=${value}&`;
        }
    }
    return queryString.slice(0, -1);
};
