// @flow
import React from 'react';
import withLocales from '../../../HOCs/withLocales';
import type {ILocalesTransformers} from '../../../utils/locales';
import ReactGA from '../../../utils/ga';
import {Icon, ButtonContainer, ButtonContentContainer, Text} from './StyledOutlineRoundedButton';
import { saveUtmToCookies } from '../../../utils';

type FreeTrialButtonProps = ILocalesTransformers & {
    buttonLink: any,
    buttonText: string,
    buttonIconPath?: string,
    buttonIconPathHovered?: string,
    trackingCategory: string,
    trackingAction: string,
    trackingLabel?: string
}

export const OutlineRoundedButton = withLocales((props: FreeTrialButtonProps): any => {
    const { buttonLink, buttonText, buttonIconPath, buttonIconPathHovered, trackingCategory, trackingAction, trackingLabel } = props;
    const onButtonClick = (link: any): any => {
        ReactGA.event({ category: trackingCategory, action: trackingAction, label: trackingLabel });
        saveUtmToCookies();
        window.open(link);
    };

    return (
        <ButtonContainer onClick={(): void => onButtonClick(buttonLink)}>
            <ButtonContentContainer>
                <Text>{buttonText}</Text>
                <Icon buttonIconPath={buttonIconPath}/>
            </ButtonContentContainer>
        </ButtonContainer>
    );
});
