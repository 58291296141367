// @flow
import React, { useContext, useEffect, useState } from 'react';
import ReactGA from '../../utils/ga';
import type {ILocalesTransformers} from '../../utils/locales';
import {LocalesContext} from '../../utils/locales';
import {FlexColumn} from '../../StyledUtils';
import {FrameContent} from '../FrameContent/FrameContent';
import {SliderContent, VideosContainer, StyledTextContainer, Title, TextContainer, Description, StyledSliderContainer, SelectedItemVideo,
    ProgressBar, Progress, ItemIcon} from './StyledSlider';

export type ISliderProps = {
    browserName: string;
    slidesToMap: Object;
    videoWidth: string;
    videoHeight: string;
    trackingCategory: string;
    trackingAction: string;
}

export const Slider = (props: ISliderProps): any => {
    const {browserName, slidesToMap, videoWidth, videoHeight, trackingCategory, trackingAction} = props;
    const isSafari = browserName === 'safari';
    const {translate}: ILocalesTransformers = useContext(LocalesContext);
    const totalSlidesToMap = slidesToMap.length;
    const [selectedItem, setSelectedItem] = useState(0);
    const onItemSelect = (itemNumber?: number) => {
        itemNumber !== undefined ? ReactGA.event({ category: trackingCategory, action: trackingAction, label: `Selected_item${itemNumber}`}) : null;
        let nextItem = itemNumber === undefined ? (selectedItem + 1) : itemNumber;
        if (nextItem > totalSlidesToMap -1) {
            nextItem = 0;
        }
        setSelectedItem(nextItem);
        const videoElement: HTMLVideoElement | null = (document.getElementById(`video${nextItem}`): any);
        videoElement?.play();
    };

    useEffect((): any => {
        const intervalId = setInterval(function() {
            onItemSelect();
        }, 7000);
        return (): any => clearInterval(intervalId);
    }, [selectedItem]);

    return (
        <StyledSliderContainer>
            <FrameContent>
                <SliderContent>
                    <VideosContainer>
                        { slidesToMap.map((slide: Object, index: number): any => (
                            <SelectedItemVideo key={slide.title} videoItem={index} isSelected={selectedItem === index}>
                                <video id={`video${index}`} width={videoWidth} height={videoHeight} muted playsInline autoPlay>
                                    <source src={slidesToMap[index].videoSrc + `${isSafari ? '.mp4' : '.webm'}`} type={`video/${isSafari ? 'mp4' : 'webm'}`}/>
                                </video>
                            </SelectedItemVideo>
                        ))}
                    </VideosContainer>
                    <StyledTextContainer>
                        { slidesToMap.map((slide: Object, index: number): any => (
                            <TextContainer key={slide.title} onClick={(): void => onItemSelect(index)}>
                                <ItemIcon isSelected={selectedItem === index} iconPath={slide.iconPath} selectedIconPath={slide.selectedIconPath}/>
                                <FlexColumn>
                                    <Title isSelected={selectedItem === index}>{translate(slide.title)}</Title>
                                    <Description isSelected={selectedItem === index}>{translate(slide.description)}</Description>
                                    <ProgressBar>
                                        <Progress isSelected={selectedItem === index}/>
                                    </ProgressBar>
                                </FlexColumn>
                            </TextContainer> ))}
                    </StyledTextContainer>
                </SliderContent>
            </FrameContent>
        </StyledSliderContainer>
    );
};
