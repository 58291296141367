// @flow

import type Node from 'react';
import React, {useContext} from 'react';
import {GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import env from '../../utils/env';
import {isNode} from '../../utils/isnode';
import {analyticsContext} from '../AnalyticsProvider/AnalyticsProvider';

export const ConnectWithGoogleHistoryProvider = ({children}: Object): Node => {
    return (
        <GoogleOAuthProvider clientId={`${!env(['configuration', 'mywotUrl']).includes('ninja') ? '616833267751-6g4lfugcbmvl9ns0e1eejk2fqbqnk3eb' : '264893803860-ghrcgel4cukjru99m7vpi4f3h7a1kslj'}.apps.googleusercontent.com`}>
            {children}
        </GoogleOAuthProvider>
    );
};

export const useConnectWithGoogleHistoryLogin = (): () => void => {
    const eventParams = JSON.stringify(useContext(analyticsContext));
    return useGoogleLogin({
        scope: 'https://www.googleapis.com/auth/webhistory',
        overrideScope: true,
        flow: 'auth-code',
        ux_mode: 'redirect',
        redirect_uri: !isNode() && `${location.origin}/have-i-been-compromised`,
        state: eventParams,
    });
};

