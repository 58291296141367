// @flow
import type Node from 'react';
import React from 'react';
import {BrowserIcon, DesktopButton, MobileButton, AppleLink, GoogleLink} from './StyledInstallWOT';
import {capitalizeString, getUtmString, saveUtmToCookies} from '../../../utils';
import ReactGA from '../../../utils/ga';
import {APP_STORE_UTM, GOOGLE_PLAY_STORE} from '../../../utils/const';
import {useDeviceInfo, useEnabledFeatureAnalytics, useTranslate} from '../../../hooks';

type InstallWOTProps = {
    styleSheet?: {
        margin?: string,
        padding?: string,
        withBoxShadow?: string,
        iconSize?: number,
        withoutIcon?: boolean,
        fontSize?: number,
        fontWeight?: number,
    },
    outLined?: boolean,
    text?: string,
    trackingCategory?: string,
    trackingAction?: string,
    extInstalledOptions?: {
        text?: string,
        link?: string
    },
    appsFlyerLink?: string
}

const googlePlayStore = GOOGLE_PLAY_STORE.concat(`&${getUtmString()}`);
const appleAppStore = `${APP_STORE_UTM}&${getUtmString()}`;

export const InstallWOT = ({text, trackingCategory, trackingAction, extInstalledOptions, styleSheet, outLined, appsFlyerLink}: InstallWOTProps): Node => {
    const {isIos, isAndroid, browserName, isExtInstalled, isExtSupported} = useDeviceInfo();
    const isSamsung = browserName === 'samsung';
    const translate = useTranslate();
    const sendAnalytics = useEnabledFeatureAnalytics();
    const onStoreClick = (label: string) => {
        sendAnalytics({type: 'GO_TO_STORE_CTA'});
        saveUtmToCookies();
        ReactGA.event({ category: trackingCategory || 'general',
            action: trackingAction || 'Install Button Click',
            label });
    };

    const onCtaClick = (link?: string) => {
        sendAnalytics({type: 'GO_TO_STORE_CTA'});
        if (link) {
            location.href = link;
        }
    };

    switch (true) {
        case isIos:
            return (
                <MobileButton>
                    <AppleLink href={appsFlyerLink || appleAppStore} target="_blank" onClick={(): void => onStoreClick('iOS')}/>
                </MobileButton>
            );
        case isAndroid && (!isSamsung || isExtInstalled):
            return (
                <MobileButton>
                    <GoogleLink href={appsFlyerLink || googlePlayStore} target="_blank" onClick={(): void => onStoreClick('Android')}/>
                </MobileButton>
            );
        case !isExtInstalled && isExtSupported:
            return (
                <DesktopButton {...{outLined, styleSheet, browserName}} onClick={(): void => onCtaClick(`/download?${getUtmString()}`)}>
                    {!styleSheet?.withoutIcon && <BrowserIcon/>}
                    <span>{translate(text || 'components.floating.add.wot.ext.cta', {browserName: capitalizeString(browserName)})}</span>
                </DesktopButton>
            );
        case isExtInstalled && Boolean(extInstalledOptions):
            const {text: extInstalledText, link} = extInstalledOptions || {};
            return (
                <DesktopButton {...{outLined, styleSheet}} onClick={(): void => link && (location.href = link)}>
                    <span>{translate(extInstalledText)}</span>
                </DesktopButton>
            );
        default:
            return <></>;
    }
};

