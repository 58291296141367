// @flow
import type Node from 'react';
import React, {useState} from 'react';
import {ModalContent, X} from './StyledWebHistoryModal';
import {ResultsPage} from './ResultsPage/ResultsPage';
import {ScanPage} from './ScanPage/ScanPage';
import {useDeviceInfo} from '../../hooks';
import Modal from '../Modal';

type ResultsModalProps = {
    googleCode: string,
    onClose: () => void,
    onConnectSuccess: () => void
}

const WebHistoryModal = ({googleCode, onClose, onConnectSuccess}: ResultsModalProps ): Node => {
    const [webHistoryResults, setWebHistoryResults] = useState(null);
    const {wotTraceIdConnect} = useDeviceInfo();

    return !webHistoryResults && !(wotTraceIdConnect || Boolean(googleCode)) ? <></> : (
        <Modal>
            <ModalContent>
                <X onClick={onClose}/>
                { webHistoryResults ?
                    <ResultsPage webHistoryResults={webHistoryResults}/> :
                    <ScanPage googleCode={googleCode} onHistoryResultsReceived={setWebHistoryResults} onConnectSuccessAfterNotFound={onConnectSuccess}/>}
            </ModalContent>
        </Modal>
    );
};

export default WebHistoryModal;
