// @flow

import React, {useContext} from 'react';
import type {Node} from 'react';
import ReactGA from '../../../utils/ga';
import {AppleLink, GoogleLink, InstallButtonContainerRow, WriteReviewButton, GetPremiumButton, GetPremiumButtonArrow, GetPremiumButtonText, CtaButton, CtaButtonText} from './StyledInstallContainer';

import { GOOGLE_PLAY_STORE, APP_STORE_UTM, EXTENSION_PREMIUM_PURCHASE_URL} from '../../../utils/const.js';

import { getUtmString, saveUtmToCookies } from '../../../utils/index';

import BrowserInstallButton, {Props} from '../install';
import withBrowserInfo from '../../../HOCs/withBrowserInfo';
import {LocalesContext} from '../../../utils/locales';
import type {ILocalesTransformers} from '../../../utils/locales';
import {useDeviceInfo} from '../../../hooks';

const googlePlayStore = GOOGLE_PLAY_STORE.concat(`&${getUtmString()}`);
const appleAppStore = `${APP_STORE_UTM}&${getUtmString()}`;

type InstallButtonContainerProps = Props & {
    forceMobile?: boolean,
    trackingCategory?: string,
    trackingAction?: string,
    browserInfo?: Object,
    isUserPremium?: boolean,
    ctaText?: string,
    showPremiumCta?: boolean,
    isExtensionInstalled?: boolean;
    customCtaHandler?: {
        onClick: Function,
        text: string,
        condition?: boolean,
    };
    wotProductSource?: string;
}

export const InstallButtonContainer = (btnProps: InstallButtonContainerProps): Node => {
    const {forceMobile, trackingCategory, trackingAction, browserInfo, isUserPremium, ctaText, showPremiumCta, wotProductSource, isExtensionInstalled, customCtaHandler, ...browserInstallButtonProps } = btnProps;
    const {isIos, isAndroid, browserName, isExtInstalled} = useDeviceInfo();
    const isSamsung = browserName === 'samsung';
    const {translate}: ILocalesTransformers = useContext(LocalesContext);
    const onStoreClick = (label: string) => {
        btnProps.onButtonClick?.();
        saveUtmToCookies();
        ReactGA.event({ category: trackingCategory || 'general',
                        action: trackingAction || 'Install Button Click',
                        label });
        };
    const onGetPremiumClicked = () => {
        saveUtmToCookies();
        ReactGA.event({ category: trackingCategory, action: 'Install_Premium', label: wotProductSource });
        location.href = `${EXTENSION_PREMIUM_PURCHASE_URL}&wot_product=${wotProductSource || ''}&${getUtmString()}`;
    };

    switch (true) {
        case (customCtaHandler && isExtInstalled):
            return (
                    <InstallButtonContainerRow>{
                        customCtaHandler.condition !== false ?
                            <CtaButton onClick={customCtaHandler.onClick}>
                                <CtaButtonText>{customCtaHandler.text}</CtaButtonText>
                            </CtaButton> :
                            <></>}
                    </InstallButtonContainerRow>
            );
        case (isAndroid && (!isSamsung || isExtInstalled) && !customCtaHandler):
            return (
                    <InstallButtonContainerRow>
                        <GoogleLink href={googlePlayStore} target="_blank" onClick={(): void => onStoreClick('Android')} />
                    </InstallButtonContainerRow>
            );
        case (isIos && !customCtaHandler):
            return (
                    <InstallButtonContainerRow>
                        <AppleLink href={appleAppStore} target="_blank" onClick={(): void => onStoreClick('iOS')} />
                    </InstallButtonContainerRow>
            );
        case (showPremiumCta && isExtInstalled && !isUserPremium && !customCtaHandler):
            return (
                    <InstallButtonContainerRow>
                        <GetPremiumButton onClick={onGetPremiumClicked}>
                            <GetPremiumButtonText>{ctaText || translate('pages.home.protect.button')}</GetPremiumButtonText>
                            <GetPremiumButtonArrow />
                        </GetPremiumButton>
                    </InstallButtonContainerRow>
            );
        case (((forceMobile && !isSamsung) || isExtInstalled) && !customCtaHandler):
            return (
                    <InstallButtonContainerRow>
                        <GoogleLink href={googlePlayStore} target="_blank" onClick={(): void => onStoreClick('Android')} />
                        <AppleLink href={appleAppStore} target="_blank" onClick={(): void => onStoreClick('iOS')} />
                    </InstallButtonContainerRow>
            );
        case (!isExtInstalled):
            return (
                    <InstallButtonContainerRow>
                        <BrowserInstallButton {...browserInstallButtonProps} wotProductSource={wotProductSource} onClick={(clickEvent: any): void => onStoreClick(clickEvent.currentTarget.dataset.gaLabel|| wotProductSource)}/>
                    </InstallButtonContainerRow>
            );
        default:
            return (<></>);
    }
};

export default withBrowserInfo(InstallButtonContainer);
