// @flow
import * as React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import merge from 'lodash/merge';
import throttle from 'lodash/throttle';

import classNames from 'classnames';
import Downshift from 'downshift';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {Menu} from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import ArrowDropUpRounded from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDownRounded from '@material-ui/icons/ArrowDropDownRounded';

import env from '../../utils/env';
import {elipse, getOS, getUtmString, isDashboardSupported, langPrefix} from '../../utils/index';
import { isPower } from '../../utils/auth';
import {isEmptyObject} from '../../utils';
import {isNode} from '../../utils/isnode';
import type {ILocalesTransformers, ITranslate} from '../../utils/locales';
import {LocalesConsumer} from '../../utils/locales';
import {mobileBreakPointInt, tabletBreakPointToInt, tabletBreakPointFromInt, desktopBreakPointInt} from '../../StyledUtils';
import withSnackbar from '../../HOCs/withSnackbar';
import withDialogRouter from '../../HOCs/withDialogRouter';
import SSR from '../../HOCs/serverRender';
import {REGEX, TOP_TLDS, MAX_SEARCH_SUGGESTIONS, GOOGLE_PLAY_STORE, APP_STORE_UTM, defaultAvatarImageHref, MY_DASHBOARD_URL, WOT_PRODUCT} from '../../utils/const';
import {WithPageScroll} from '../../components/WithPageScroll/WithPageScroll';
import {InstallWOT} from '../../components/buttons/InstallWOT/InstallWOT';
import Avatar from '../../components/Avatar/Avatar';
import LanguageChooser from '../../components/LanguageChooser/LanguageChooser';
import styles from './styles';
import menu from './menu.json';
import account from './account.json';
import {
    HeaderContainer,
    Root,
    FlexRight,
    AlignRight,
    Logo,
    LogIn,
    AlignLeft,
    ToolbarContainer,
    StyledToolbar,
    InvisibleLoginForExternalTrigger,
    SearchBarHomepageContainer,
    SearchBarHomepageRoot,
    SearchBarIconHomepage,
    SearchButtonHomepage,
    SearchBarHomepageDropdownIcon,
    FlexFullWidth,
    MenuItemsContainer,
    MenuItemIcon,
    MenuItemTitle,
    MenuItemSubtitle,
    MenuItemTextContainer,
    InstallWotPlaceHolder
} from './StyledHeader';

type Props = {
    acknowledge: Function,
    auth: Object,
    classes: Object,
    history: Object,
    lang: string,
    lastAction: Object,
    location: Object,
    logout: Function,
    theme: Object,
    isMobile: boolean,
    user?: Object
}

type State = {
    account: Object,
    accountAnchor: ?Object,
    menu: Array<Object>,
    menuAnchor: boolean,
    search: Object,
    searchAnchor: boolean,
}

let dataAutomation = {
    desktop: {
        search: {
            label: {
                selected: false,
            },
            input: {
                selected: false,
            },
        },
    },
};

const installButtonCss = {
    padding: '9px 16px',
    fontSize: 16,
    fontWeight: 500,
    iconSize: 20
};

const goToDashboardProps = {
    text: 'components.header.go-to-dashboard',
    link: `${MY_DASHBOARD_URL}?${WOT_PRODUCT}=site-menu_bar`
};

export const mobileHeaderHiddenPoint = 170;

class Header extends React.Component<Props, State> {
    toggleAccount: Function;

    constructor(props: Props) {
        super(props);

        this.state = {
            account,
            accountAnchor: null,
            menu,
            menuAnchor: false,
            searchAnchor: false,
            search: {
                value: '',
                opened: false,
            },
        };

        this.toggleAccount = throttle(this.toggleMenu('account', true), 20);
    }

    componentDidMount() {
        if (env('ENV') !== 'production') {
            window.header = this;
        }
    }

    static getDerivedStateFromProps(props: Props, state: State): ?Object {
        const subs = [...account.subs];

        if (props.auth) {
            subs.push({
                name: 'My Apps',
                locale: 'components.header.button.user.apps',
                url: `${env(['configuration', 'mywotAppUrl'])}/apps`,
                style: { color: 'default' },
                action: () => {
                },
            });
            subs.push({
                name: 'My Sites',
                locale: 'components.header.button.user.mysites',
                url: '/user/:uid/sites',
                style: { color: 'default' },
                action: () => {
                },
            });
            if (props.auth.apiKey) {
                subs.push({
                    name: 'Developer API',
                    locale: 'components.header.button.user.developer',
                    url: `${env(['configuration', 'mywotAppUrl'])}/developer/api`,
                    style: { color: 'default' },
                    action: () => {
                    },
                });
            }
        }

        if (isPower(props.auth)) {
            subs.push({
                name: 'Mass Rating',
                locale: 'components.header.button.user.mass',
                url: '/user/:uid/mass-rating',
                style: { color: 'default' },
                action: () => {
                },
            });
        }

        subs.push({
            'name': 'Log out',
            'locale': 'components.header.button.user.logout',
            'action': props.logout,
            'style': { color: 'red' },
        });

        return { account: merge({}, state.account, { subs }) };
    }

    expandMenu(index: number | string): Function {
        return throttle((event: Object) => {
            event.stopPropagation();
            if (!event.nativeEvent) {
                return;
            }
            if (typeof index === 'string') {
                if (index === 'account') {
                    this.setState({ accountAnchor: event.currentTarget });
                } else {
                    this.setState({ menuAnchor: true });
                }
            } else {
                const menu = [...this.state.menu];
                menu[index].anchor = event.currentTarget;
                this.setState({ menu });
            }
        }, 1000);
    }

    hideMenu(index: number | string, synthetic: boolean = false): Function {
        return (event: Object) => {
            if (typeof index === 'string') {
                if (index === 'account') {
                    this.setState({ accountAnchor: null });
                } else {
                    this.setState({ menuAnchor: false });
                }
            } else {
                const menu = [...this.state.menu];
                menu[index].anchor = null;
                this.setState({ menu });
            }
        };
    }

    toggleMenu(index: number | string, synthetic: boolean = false): Function {
        return (event: Object) => {
            event.persist && event.persist();
            event.stopPropagation();
            if (!synthetic && !event.nativeEvent) {
                return;
            } else {
                event.nativeEvent && event.nativeEvent.stopImmediatePropagation();
            }
            const target = event.currentTarget;
            if (typeof index === 'string') {
                if (index === 'account') {
                    this.setState((state: State): Object => ({
                        accountAnchor: state.accountAnchor ? null : target,
                    }));
                } else {
                    this.setState((state: State): Object => ({ menuAnchor: !state.menuAnchor }));
                }
            } else {
                const menu = [...this.state.menu];
                menu[index].anchor = this.state.menu[index] ? null : target;
                this.setState({ menu });
            }
        };
    }

    toggleSearch() {
        const searchAnchor: boolean = !(this.state.searchAnchor);
        this.setState({ searchAnchor });
    }

    changeField(name: string): Function {
        return (event: string | Object) => {
            let value = '';
            if (typeof event === 'string') {
                value = event;
            } else {
                value = event.target.value;
            }
            this.setState((state: State): Object =>
                ({ [name]: merge(state[name], { value: value.replace(/[\s]/gm, '') }) }));
        };
    }

    search() {
        if (this.state.search.value) {
            this.stripUrlAndNavigate(this.state.search.value);
        }
    }

    stripUrlAndNavigate(url: string) {
        const { lang } = this.props;
        const tmpTarget = `${langPrefix({lang})}/scorecard/${url
            .replace(/(https:\/\/|http:\/\/)/gi, '')
            .replace(/(www\.)/g, '')
            .replace(/(\/$)/g, '')
            .replace(/(\/|\?|#&).*/, '')
        }`;

        location.pathname = tmpTarget.indexOf('.') === -1 ? tmpTarget + '.com' : tmpTarget;
    }

    render(): any {
        const { classes, isMobile, lang, withHeaderBorder, deviceInfo} = this.props;

        const desktop = (translate: ITranslate): React.Node => (
            <ToolbarContainer>
                <StyledToolbar withHeaderBorder={withHeaderBorder} data-automation={'toolbar'}>
                    <Logo lang={lang}/>
                    <SearchBarHomepageRoot>
                            <SearchBarHomepageContainer dropdownOpen={this.state.search.value}>
                                <StyledToolbar disableGutters={true}>
                                    {this.renderSearchInput(false, translate)}
                                </StyledToolbar>
                            </SearchBarHomepageContainer>
                    </SearchBarHomepageRoot>
                    <AlignRight>
                        <LanguageChooser language={lang} isInListMenu={false} dataAutomationId={'-header'}/>
                        {this.renderDesktopMenuItems(translate)}
                        <InstallWotPlaceHolder>
                            <InstallWOT trackingCategory={'Desktop'} trackingAction={'Top_Install'} styleSheet={installButtonCss}
                                        extInstalledOptions={deviceInfo.isDashboardSupported && goToDashboardProps} outLined/>
                        </InstallWotPlaceHolder>
                        {this.renderUser(translate)}
                    </AlignRight>
                </StyledToolbar>
            </ToolbarContainer>
        );

        const tablet = (translate: ITranslate): React.Node => (

            <StyledToolbar withHeaderBorder={withHeaderBorder} data-automation={'toolbar'} >
                <AlignLeft>
                {
                 this.state.searchAnchor ? (
                        <Grid
                            container
                            className={classes.searchRoot}
                            spacing={1}
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Grid item>
                                <IconButton
                                    aria-label='close'
                                    aria-owns={this.state.searchAnchor ? 'extend-menu' : null}
                                    aria-haspopup='true'
                                    onClick={this.toggleSearch.bind(this)}
                                >
                                    <Close/>
                                </IconButton>
                            </Grid>
                            {this.renderSearchInput(true, translate)}
                            <Grid item>
                                <IconButton
                                    className={classes.mobileMenuIcon}
                                    aria-label='search'
                                    aria-owns={this.state.searchAnchor ? 'extend-menu' : null}
                                    aria-haspopup='true'
                                    onClick={this.search.bind(this)}
                                >
                                    <Search/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            className={classes.searchRoot}
                            spacing={1}
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <IconButton
                                className={classes.mobileMenuIcon}
                                aria-label='menu'
                                aria-owns={this.state.menuAnchor ? 'long-menu' : null}
                                aria-haspopup='true'
                                onClick={this.state.menuAnchor ? this.hideMenu('mobile') : this.expandMenu('mobile')}
                                id={'menuButton'}
                            >
                                {this.state.menuAnchor ? (<Icon>close</Icon>) : (<Icon>menu</Icon>)}
                            </IconButton>
                            <SwipeableDrawer
                                swipeAreaWidth={0}
                                anchor='top'
                                open={this.state.menuAnchor}
                                // TODO: submenus close when swipe is closed
                                onClose={this.hideMenu('mobile')}
                                onOpen={this.expandMenu('mobile')}
                                PaperProps={{ className: classes.drawer }}
                                ModalProps={{
                                    BackdropProps: {
                                        className: classes.backdrop,
                                        onClick: this.hideMenu('modal'),
                                    },
                                }}
                            >
                                <List disablePadding>
                                    {Array.from(this.state.menu).reverse().map((option: Object, index: number): Node => {
                                        if (option.subs) {
                                            return (
                                                <div key={index}>
                                                    <ListItem
                                                        divider
                                                        className={classes.mobileMenuItem}
                                                        button
                                                        onClick={this.state.menu[index].anchor
                                                            ? this.hideMenu(index)
                                                            : this.expandMenu(index)}
                                                    >
                                                        <ListItemText primary={translate(option.locale)}/>
                                                        {this.state.menu[index].anchor ? <ExpandLess/> :
                                                            <ExpandMore/>}
                                                    </ListItem>
                                                    <Collapse in={Boolean(this.state.menu[index].anchor)}
                                                              timeout='auto'
                                                              unmountOnExit>
                                                        <List component='div' disablePadding>
                                                            {option.subs.map((sub: Object, j: number): Node => {
                                                                return (
                                                                    <ListItem divider={j !== option.subs.map}
                                                                              className={classNames(
                                                                                  classes.mobileMenuItem,
                                                                                  classes.mobileMenuSubItem)}
                                                                              component='a'
                                                                              href={sub.url}
                                                                              key={sub.name + j}>
                                                                        <ListItemText
                                                                            classes=
                                                                                {{primary: classes.menuItemPadding}}
                                                                            primary={translate(sub.locale)}/>
                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                    </Collapse>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <ListItem divider className={classes.mobileMenuItem} key={index}
                                                          component='a' href={option.url}>
                                                    <ListItemText primary={translate(option.locale)}/>
                                                </ListItem>
                                            );
                                        }
                                    })}
                                    <LanguageChooser language={lang} isInListMenu={true} dataAutomationId={'-header'}/>
                                    {this.renderDownloadTheApp(translate)}
                                </List>
                            </SwipeableDrawer>

                <Logo lang={lang}/>

            {this.renderSearchInput(false, translate)}

                </Grid>
                    )}
            </AlignLeft>
            <AlignRight>
                {this.renderUser(translate)}
            </AlignRight>
            </StyledToolbar>
        );


        const mobile = (translate: ITranslate): React.Node => (
            <StyledToolbar withHeaderBorder={withHeaderBorder} key='mobileToolbar'>
                <AlignRight>
                {
                    this.state.searchAnchor ? (
                        <Grid
                            container
                            className={classes.searchRoot}
                            spacing={1}
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Grid item>
                                <IconButton
                                    aria-label='close'
                                    aria-owns={this.state.searchAnchor ? 'extend-menu' : null}
                                    aria-haspopup='true'
                                    onClick={this.toggleSearch.bind(this)}
                                >
                                    <Close/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                {this.renderSearchInput(true, translate)}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            className={classes.searchRoot}
                            spacing={1}
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <InvisibleLoginForExternalTrigger>
                                <Link to={`login`}>
                                    <LogIn id={'loginButton'} />
                                </Link>
                            </InvisibleLoginForExternalTrigger>
                            <IconButton
                                aria-label='menu'
                                aria-owns={this.state.menuAnchor ? 'long-menu' : null}
                                aria-haspopup='true'
                                onClick={this.state.menuAnchor ? this.hideMenu('mobile') : this.expandMenu('mobile')}
                                id={'menuButton'}
                            >
                                {this.state.menuAnchor ? (<Icon>close</Icon>) : (<Icon>menu</Icon>)}
                            </IconButton>
                            {!this.state.menuAnchor && <Logo lang={lang}/>}
                            <Grid item>
                                <IconButton
                                    className={!this.state.menuAnchor
                                        ? classes.mobileMenuIcon
                                        : classNames(classes.mobileMenuIcon, classes.hidden)}
                                    aria-label='search'
                                    aria-owns={this.state.searchAnchor ? 'extend-menu' : null}
                                    aria-haspopup='true'
                                    onClick={this.toggleSearch.bind(this)}
                                >
                                    <Search/>
                                </IconButton>
                            </Grid>
                            <SwipeableDrawer
                                swipeAreaWidth={0}
                                anchor='top'
                                open={this.state.menuAnchor}
                                onClose={this.hideMenu('mobile')}
                                onOpen={this.expandMenu('mobile')}
                                PaperProps={{ className: classNames(classes.drawer, deviceInfo.shouldShowCookiesConsent ? classes.drawerTopWithCookies: '') }}
                                ModalProps={{
                                    BackdropProps: {
                                        className: classes.backdrop,
                                        onClick: this.hideMenu('modal'),
                                    },
                                }}
                            >
                                <List disablePadding>
                                    {this.renderMobileUser(translate)}
                                    {Array.from(this.state.menu).reverse().map((option: Object, index: number): Node => {
                                        if (option.subs) {
                                            return (
                                                <div key={index}>
                                                    <ListItem
                                                        divider
                                                        className={classes.mobileMenuItem}
                                                        button
                                                        onClick={this.state.menu[index].anchor
                                                            ? this.hideMenu(index)
                                                            : this.expandMenu(index)}
                                                    >
                                                        <ListItemText primary={translate(option.locale)}/>
                                                        {this.state.menu[index].anchor ? <ExpandLess/> :
                                                            <ExpandMore/>}
                                                    </ListItem>
                                                    <Collapse in={Boolean(this.state.menu[index].anchor)}
                                                              timeout='auto'
                                                              unmountOnExit>
                                                        <List component='div' disablePadding>
                                                            {option.subs.map((sub: Object, j: number): Node => {
                                                                return (
                                                                    <ListItem divider={j !== option.subs.map}
                                                                              className={classNames(
                                                                                  classes.mobileMenuItem,
                                                                                  classes.mobileMenuSubItem)}
                                                                              component='a'
                                                                              href={sub.url}
                                                                              key={sub.name + j}>
                                                                        <ListItemText
                                                                            classes=
                                                                                {{ primary: classes.menuItemPadding }}
                                                                            primary={translate(sub.locale)}/>
                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                    </Collapse>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <ListItem divider className={classes.mobileMenuItem} key={index}
                                                          component='a' href={option.url}>
                                                    <ListItemText primary={translate(option.locale)}/>
                                                </ListItem>
                                            );
                                        }
                                    })}
                                    <LanguageChooser language={lang} isInListMenu={true} dataAutomationId={'-header'}/>
                                    {this.renderDownloadTheApp(translate)}
                                </List>
                            </SwipeableDrawer>


                        </Grid>
                    )
                }
                </AlignRight>
            </StyledToolbar>
        );
/*
 * It's decided to handle the tablet mode only on client side.
 */
        return (
            <LocalesConsumer>
                {
                    ({translate}: ILocalesTransformers): React.Node => (
                        <Root isCookiesConsentVisible={deviceInfo.shouldShowCookiesConsent}>
                            <WithPageScroll yPoint={mobileHeaderHiddenPoint}>
                                {
                                    (isHidden: boolean): any => (
                                        <HeaderContainer isHidden={isHidden} isCookiesConsentVisible={deviceInfo.shouldShowCookiesConsent}>
                                            <SSR
                                                OnServer={
                                                    <AppBar color='secondary' position='fixed' className={classes.onTop}>
                                                        {isMobile ? mobile(translate) : desktop(translate)}
                                                    </AppBar>
                                                }
                                                OnClient={
                                                    <AppBar position='fixed' color='secondary' className={classes.onTop} id={'headerContainer'}>
                                                        <MediaQuery minWidth={desktopBreakPointInt}>
                                                            {desktop(translate)}
                                                        </MediaQuery>

                                                        <MediaQuery minWidth={tabletBreakPointToInt} maxWidth={tabletBreakPointFromInt}>
                                                            {tablet(translate)}
                                                        </MediaQuery>

                                                        <MediaQuery maxWidth={mobileBreakPointInt}>
                                                            {mobile(translate)}
                                                        </MediaQuery>
                                                    </AppBar>
                                                }
                                            />
                                        </HeaderContainer>
                                    )
                                }
                            </WithPageScroll>
                        </Root>
                    )
                }
            </LocalesConsumer>
        );
    }

    renderDesktopMenuItems(translate: ITranslate): Node {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.menu.map((elem: Object, i: number): Node => {
                        if (elem.subs) {
                            return (
                                <div key={'Desktop' + i}>
                                    <Button
                                        component='a'
                                        data-automation={`desktop-menu-item`}
                                        href={elem.url}
                                        className={classes.buttonLink}
                                        aria-owns={elem.anchor ? 'simple-menu' + i : null}
                                        aria-haspopup='true'
                                        onClick={this.expandMenu(i)}
                                    >
                                            <span data-automation={`desktop-menu-label`} id={'buttonLink'}>
                                                {translate(elem.locale)}
                                            </span>
                                        <ArrowDropDownRounded/>
                                    </Button>
                                    <ClickAwayListener
                                        mouseEvent={'onMouseDown'}
                                        onClickAway={this.hideMenu(i)}
                                    >
                                        <Menu
                                            id={'simple-menu' + i}
                                            anchorEl={elem.anchor}
                                            open={Boolean(elem.anchor)}
                                            className={classes.simpleMenu}
                                            classes={{
                                                paper: classes.simpleMenuWrapper
                                            }}
                                            onClose={this.hideMenu(i)}
                                            onKeyDown={(e: Object) => {
                                                if (e.keyCode === 27) {
                                                    e.persist();
                                                    this.hideMenu(i)(e);
                                                }
                                            }}
                                        >
                                            {elem.subs.map((sub: Object, j: number): Node => {
                                                const chooseMenu = (event: Object) => {
                                                    this.hideMenu('account');
                                                };
                                                return (
                                                    <MenuItem
                                                        component='a'
                                                        data-automation={`desktop-submenu-item`}
                                                        href={sub.url}
                                                        key={j}
                                                        onClick={chooseMenu}
                                                        id={`wot-header-menu-${sub.name}`}
                                                        data-ga-label={sub.name}
                                                        className={classes.menuItem}
                                                    >
                                                        {sub.subtitle ?
                                                            <MenuItemsContainer>
                                                                <MenuItemIcon src={sub.image}/>
                                                                <MenuItemTextContainer>
                                                                    <MenuItemTitle
                                                                        data-automation={`desktop-submenu-label`}>{translate(sub.locale)}</MenuItemTitle>
                                                                    <MenuItemSubtitle
                                                                        data-automation={`desktop-submenu-label`}>{translate(sub.subtitle)}</MenuItemSubtitle>
                                                                </MenuItemTextContainer>
                                                            </MenuItemsContainer>
                                                            :
                                                            <MenuItemTitle data-automation={`desktop-submenu-label`}>{translate(sub.locale)}</MenuItemTitle>
                                                        }
                                                    </MenuItem>
                                                );
                                            })}
                                        </Menu>
                                    </ClickAwayListener>
                                </div>
                            );
                        } else {
                            return (
                                <Button
                                    component='a'
                                    key={i}
                                    href={elem.url}
                                    className={classes.buttonLink}
                                    id={`wot-header-menu-${elem.name}`}
                                    data-ga-label={elem.name}
                                    data-automation={`desktop-menu-item`}
                                >
                                        <span
                                            data-automation={`desktop-menu-label`}
                                            id={'buttonLink'}
                                        >
                                            {translate(elem.locale)}
                                            </span>
                                </Button>
                            );
                        }
                })}
            </React.Fragment>
        );
    };

    renderUser(translate: ITranslate): Node {
        let { classes, auth, user } = this.props;
        auth = user || auth;
        const isLoggedIn = Boolean((auth && auth.token) || (!isEmptyObject(user) && user.status === 'WOTUSER_DATA_SUCCEED'));
        switch (true) {
            case (isLoggedIn):
                return (
                    <ClickAwayListener
                        mouseEvent={'onMouseDown'}
                        onClickAway={this.hideMenu('account', true)}
                    >
                        <div>
                            <Button
                                className={classes.desktopAccount}
                                onClick={this.toggleAccount.bind(this)}
                                data-automation={'avatar'}
                            >
                                <Avatar
                                    href={auth.picture}
                                    name={auth.name}
                                    customWidthAndHeight={40}
                                />
                                {
                                    Boolean(this.state.accountAnchor)
                                        ? (<ArrowDropUpRounded/>)
                                        : (<ArrowDropDownRounded/>)
                                }
                            </Button>

                            <Popper
                                open={Boolean(this.state.accountAnchor)}
                                anchorEl={this.state.accountAnchor}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }: Object): Node => (
                                    <Grow
                                        {...TransitionProps}
                                        id='menu-list-grow'
                                        style={{ transformOrigin: 'center top' }}
                                    >
                                        <Paper className={classes.profileMenu}>
                                            <MenuList>
                                                {this.state.account.subs.map((sub: Object, j: number): Node => {
                                                    const chooseMenu = (event: Object) => {
                                                        sub.action && sub.action(event);
                                                    };
                                                    return (
                                                        <MenuItem
                                                            component='a'
                                                            href={sub.url ? sub.url.replace(':uid', auth && auth.uid) : '#'}
                                                            key={sub.name}
                                                            data-automation={
                                                                `desktop-usermenu-option-${sub.name.replace(' ', '-')}`// eslint-disable-line
                                                            }
                                                            onClick={chooseMenu}
                                                            style={sub.style || {}}
                                                            className={classes.profileMenuItem}
                                                        >
                                                            {translate(sub.locale)}
                                                        </MenuItem>);
                                                })}
                                            </MenuList>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </ClickAwayListener>
                );
            case (auth && auth.status && ['WOTUSER_TOKEN_REQUESTED', 'WOTUSER_DATA_REQUESTED', 'FORGOT_PASS_REQUESTED'].includes(auth.status)):
                return (
                    <CircularProgress color='primary'/>
                );
            case (!auth || !auth.token || ['WOTUSER_TOKEN_FAILED', 'WOTUSER_DATA_FAILED', 'FORGOT_PASS_FAILED'].includes(auth.status)):
            default:
                return (
                    <FlexRight>
                        <Link to={`login`} data-automation={'desktop-login-button'} id={'loginButton'}>
                            <Avatar
                                href={defaultAvatarImageHref}
                                customWidthAndHeight={40}
                            />
                        </Link>
                    </FlexRight>
                );
        }
    }

    renderMobileUser(translate: ITranslate): Node {
        const { classes, auth } = this.props;

        if (Object.keys(this.props.auth).length !== 0) {
            return (
                <div className={classes.userAccBlock}>
                    <ListItem key='Account'
                              divider
                              className={classes.mobileMenuItem}
                              button
                              onClick={this.state.accountAnchor
                                  ? this.hideMenu('account')
                                  : this.expandMenu('account')
                              }
                    >
                        <Icon className={classes.accountIcon}>
                            <Avatar
                                href={auth.picture}
                                name={auth.name}
                                customWidthAndHeight={40}
                            />
                        </Icon>
                        <ListItemText primary={elipse(auth.name, 20, 0)}/>
                        {this.state.accountAnchor ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse
                        in={Boolean(this.state.accountAnchor)}
                        timeout='auto'
                        unmountOnExit
                    >
                        <List
                            component='div'
                            disablePadding
                        >
                            {this.state.account.subs.map((sub: Object, i: number): Node => {
                                return (
                                    <ListItem divider
                                              className={classNames(classes.mobileMenuItem, classes.mobileMenuSubItem)}
                                              key={'Account' + i}
                                              onClick={sub.action || (() => {
                                              })}
                                              component={'a'}
                                              href={sub.url ? sub.url.replace(':uid', auth.uid) : '#'}
                                    >
                                        <ListItemText
                                            classes={{ primary: classes.menuItemPadding }}
                                            primary={translate(sub.locale)}
                                            primaryTypographyProps={{ style: sub.style }}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>
                </div>
            );
        } else {
            return (
                <div className={classes.authBlock}>
                    <ListItem
                        divider
                        className={classes.mobileMenuItem}
                        key='login'
                        component={Link}
                        to='/login'
                        onClick={this.hideMenu('mobile')}
                        id={'loginButton'}
                    >
                        <ListItemText primary={translate('components.header.button.login')}/>
                    </ListItem>
                </div>
            );
        }
    }

    renderSearchInput(isMobile: boolean, translate: ITranslate): Node {
        return (
            <Downshift
                id={isMobile ? 'input-with-icon-grid' : 'downshift-0'}
                onChange={(item: Object): void => this.stripUrlAndNavigate(item.value)}
                inputValue={this.state.search.value.replace(/[\s]/gm, '') || ''}
                onInputValueChange={(inputValue: string, stateAndHelpers: Object) => {
                    stateAndHelpers.setHighlightedIndex(null);
                    this.changeField('search')(inputValue, stateAndHelpers);
                }}
                itemToString={(item: Object): string => item && item.value || ''}
            >
                {this.renderSuggestions.bind(this, {isMobile, translate})}
            </Downshift>
        );
    }

    renderSuggestions(params: Object, options: Object): Node {
        const {isMobile, translate} = params;
        const { classes } = this.props;
        const {
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            highlightedIndex,
        } = options;

        // Check if the user started typing one of common TLDs
        const suggestions = getSuggestions(inputValue);

        return (
            <div className={classes.search}>
                <ClickAwayListener onClickAway={
                    () => {
                        dataAutomation.desktop.search.input.selected = false;
                        this.forceUpdate();
                    }
                }>
                    <FlexFullWidth>
                        <TextField
                            placeholder={translate('pages.home.header.search-input.placeholder')}
                            label={translate('general.search')}
                            autoFocus={true}
                            fullWidth={true}
                            data-automation={'desktop-search'}
                            id='search-input'
                            InputProps={{
                                type: 'url',
                                tabIndex: 0,
                                onFocus: () => {
                                    dataAutomation.desktop.search.input.selected = true;
                                    this.forceUpdate();
                                },
                                onKeyPress: (event: Object) => {
                                    if (event.key === 'Enter') {
                                        this.search.bind(this)();
                                    }
                                },
                                'data-automation': `desktop-search-input${dataAutomation.desktop.search.input.selected ? '-selected' : ''}`,
                                disableUnderline: !isMobile,
                                classes: {
                                    root: isMobile ? classes.mobileSearchInput : classes.searchRoot,
                                    input: isMobile ? null : classes.searchInput,
                                    underline: classes.searchUnderline,
                                },
                                ...getInputProps(),
                            }}
                            InputLabelProps={{
                                'data-automation': `desktop-search-label`,
                                'shrink': true,
                                'className': classes.searchFormLabel,
                            }}
                        />
                        <SearchButtonHomepage onClick={this.search.bind(this)}>
                            <SearchBarIconHomepage/>
                        </SearchButtonHomepage>
                    </FlexFullWidth>
                </ClickAwayListener>
                {isOpen && inputValue ? (
                    <Paper className={classes.paper} data-automation={'suggestions'} square>
                        {suggestions
                            .map((item: string, index: number): Node => {
                                    const isHighlighted = highlightedIndex === index;
                                    return (
                                        <MenuItem
                                            {...getItemProps({
                                                key: item,
                                                index,
                                                item: { value: item },
                                            })}
                                            key={item}
                                            selected={isHighlighted}
                                            data-automation={'suggestion'}
                                            component='div'
                                            style={{
                                                fontWeight: isHighlighted ? 500 : 400,
                                                justifyContent: 'space-between',
                                                padding: '10px 25px'
                                            }}
                                            onClick={this.stripUrlAndNavigate.bind(this, item)}
                                        >
                                            {item}
                                            <SearchBarHomepageDropdownIcon />
                                        </MenuItem>
                                    );
                                }
                            )
                        }
                    </Paper>
                ) : null}
            </div>
        );
    }

    renderDownloadTheApp(translate: ITranslate): Node {
        const {classes} = this.props;
        const os = isNode()? '' : getOS();
        const googlePlayStore = (GOOGLE_PLAY_STORE.replace('utmCampaign', 'MainPage')).concat(`&${getUtmString()}`);
        const appleAppStore = `${APP_STORE_UTM}&${getUtmString()}`;
        return (
            <ListItem className={classNames(classes.mobileMenuItem, classes.mobileMenuDownloadLink)}
                  component='a' href={os === 'iOS' ? appleAppStore: googlePlayStore}>
                <ListItemText primary={translate('components.header.download-the-app')} disableTypography/>
                <OpenInNew/>
            </ListItem>
        );
    }
}

function getAllPossibleTldPrefixes(inputValue: string): Array<string> {
    let possibleTldPrefixes = [];

    // We use length-1 since we don't want to return '.' as TLD prefix
    // (we require at least 1 letter of the TLD)
    for (let i=0; i<inputValue.length-1; i++) {
        if (inputValue[i] === '.') {
            possibleTldPrefixes.push(inputValue.substring(i));
        }
    }

    return possibleTldPrefixes;
}

function applyTldOnInputValue(inputValue: string,
                              tldPrefix: string,
                              tldToApply: string): string {
    return inputValue.substring(0, inputValue.length - tldPrefix.length).concat(tldToApply);
}

function getSuggestions(inputValue: string): Array<string> {
    // normalize user input value
    inputValue = inputValue.toLowerCase();

    // If input value looks like an IPv4 pattern, we should
    // not return any suggestions
    if (REGEX.IPV4_SUBSTRING.test(inputValue)) {
        return [];
    }

    // Calculate the possible TLDs the user typed
    let possibleTldPrefixes = getAllPossibleTldPrefixes(inputValue);

    // Find all the TLDs that could match the input value and add them to the suggestion list
    // For example: let's say the user typed "www.something.co", we will add ".com", ".co.il, etc."
    // If the user typed "www.something.co.". we will add ".co.il, .co.uk, etc."
    // We also save the position of the first TLD match as this is the longest TLD-prefix possible
    // We do that because we want to show around 4-5 suggestions. If we don't find enough TLDs, we
    // would like to suggest the domain under a different TLD. For example: if the user typed
    // "www.example.r", we would like to show "www.example.ru" but also "www.example.com" and not "www.example.r.com"
    let suggestions = [];
    let topTlds = TOP_TLDS.slice(0);

    // Set the initial value of the longestTldPrefix.
    // If the inputValue ends with a '.', we use '.' to avoid from suggestions like "example..com"
    let longestTldPrefix = inputValue.endsWith('.') ? '.' : '';

    for (let i=0; i<possibleTldPrefixes.length && suggestions.length < MAX_SEARCH_SUGGESTIONS; i++) {
        for (let j=0; j<topTlds.length && suggestions.length < MAX_SEARCH_SUGGESTIONS; j++) {
            if (topTlds[j].startsWith(possibleTldPrefixes[i])) {
                // Add the suggestion
                const suggestion = applyTldOnInputValue(inputValue, possibleTldPrefixes[i], topTlds[j]);
                if (suggestions.indexOf(suggestion) === -1) { // remove duplications
                    suggestions.push(suggestion);
                }

                // Remove this TLD in order not to recommend it twice
                topTlds.splice(j, 1);

                // Update the longestTldPrefix variable if needed
                if (longestTldPrefix.length < possibleTldPrefixes[i].length) {
                    longestTldPrefix = possibleTldPrefixes[i];
                }
            }
        }
    }

    // Complete to number of suggestions
    for (let i=0; i<topTlds.length && suggestions.length < MAX_SEARCH_SUGGESTIONS; i++) {
        suggestions.push(applyTldOnInputValue(inputValue, longestTldPrefix, topTlds[i]));
    }

    return suggestions;
}

function mapStateToProps(state: Object): Object {
    return {
        auth: state.auth || {},
        deviceInfo: state.deviceInfo || {},
    };
}

function mapDispatchToProps(dispatch: Function): Object {
    return {
        logout: () => {
            dispatch({ type: 'LOGOUT_REQUESTED' });
        },
    };
}

export default withRouter(withDialogRouter(withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Header))
)));
