import styled, {keyframes} from 'styled-components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {FlexColumn, FlexColumnCentered, FlexRow, mobileBreakPoint} from '../../../StyledUtils';
import {imgsPath} from '../../../utils/const';

export const HeaderSection = styled(FlexColumn)`
    align-items: center;
    text-align: center;
    width: 100%;
    @media (max-width: ${mobileBreakPoint}){
        width: 70%;
    }
`;

export const WotLogo = styled.div`
    margin-bottom: 16px;
    width: 54px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${imgsPath}/logo.svg);
    @media (max-width: ${mobileBreakPoint}){
        margin-bottom: 8px;
        width: 72px;
        height: 32px;
    }
`;

export const Header = styled.span`
    font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    @media (max-width: ${mobileBreakPoint}){
        font-size: 16px;
    }
`;

export const HeaderSubtitle = styled.span`
    margin: 16px 0 24px;
    color: #545454;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
`;

export const ConnectWithGoogleBox = styled(FlexColumn)`
    align-items: center;
    max-width: 270px;
`;

export const TermsDescription = styled.span`
    margin-top: 16px;
    color: #B3B3B3;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    & > a {
        text-decoration: underline !important;
    }
`;

export const Terms = styled.a`
    color: #545454;
`;

export const RetryScan = styled.div`
    cursor: pointer;
    padding: 12px 24px;
    border-radius: 99px;
    background: #057AFF;
    font-size: 18px;
    font-weight: 500;
    color: white;
`;

export const Contact = styled.a.attrs({
    href: '/support'
})`
    margin-top: 8px;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 500;
    color: #057AFF;
`;

export const ProgressSection = styled(FlexColumnCentered)`
    margin: 24px 0;
    align-items: center;
    font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif;
    font-size: 40px;
    font-weight: 300;
    color: #037AFF;
    width: 100%;
    max-width: 362px;
`;

export const ProgressCounter = styled.span`
    text-align: end;
    width: 4ch;
`;

export const ProgressBar = styled.div`
    margin-top: 19px;
    height: 8px;
    width: 100%;
    background: #165ADA33;
    border-radius: 12px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25) inset;
`;

const Loader = keyframes`
    0% {
        transform: translateX(-100%);
    }
    35% ,100%{
        transform: translateX(100%);
    }
`;

export const ProgressLoader = styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: inherit;
    width: 0;
    background: linear-gradient(135deg, #037AFF 0%, #03e2ff 100%);
    box-shadow: 0 3px 4px 0 rgba(255, 255, 255, 0.50) inset, 0 -2px 4px 0 rgba(0, 0, 0, 0.25) inset;
    transition: linear .1s;
`;

export const ProgressLoaderFlash = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    background: linear-gradient(200deg, transparent 20%, rgba(255,255,255,0.6) 50%, transparent 80%);
    animation: ${Loader} 1s linear infinite;
`;

export const DownloadSection = styled(FlexRow)`
    align-items: start;
    justify-content: space-between;
    box-sizing: border-box;
    border: ${({isFullPageFlow}) => isFullPageFlow ? 'none': '1px solid #057AFF'};
    border-radius: 8px;
    max-width: 450px;
    padding: 16px 16px 16px 24px;
    background: ${({isFullPageFlow}) => isFullPageFlow ? '#F1F7FD': 'transparent'};
    margin-top: ${({isFullPageFlow}) => isFullPageFlow ? 40: 0}px;
    @media (max-width: ${mobileBreakPoint}){
        margin-top: 16px;
    }
`;

export const InfoIcon = styled(InfoOutlinedIcon)`
    color: #057AFF;
`;

export const DownloadDescription = styled.span`
    margin-left: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: start;
`;
