// @flow
import * as React from 'react';

export type ITranslate = (key: string, params?: {}) => string;
export type IPluralTranslate = (count: number, keys: string[], params?: {}) => string;
export type IGetNumberLocale = (number: number, formatParam?: string) => string;
export type IGetDateLocale = (date: string | number | {}, formatParam?: string) => string;

export type ILocalesTransformers = {
    translate: ITranslate,
    pluralTranslate: IPluralTranslate,
    getNumberLocale: IGetNumberLocale,
    getDateLocale: IGetDateLocale
};

export const LocalesContext: any = React.createContext({});
export const LocalesProvider = LocalesContext.Provider;
export const LocalesConsumer = LocalesContext.Consumer;
