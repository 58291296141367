// @flow
import * as React from 'react';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog/index';
import Close from '../../../components/buttons/close/index';
import env from '../../../utils/env';
import styles from './styles';
import {LocalesConsumer} from '../../../utils/locales';
import type {ILocalesTransformers} from '../../../utils/locales';

type Props = {
    acknowledge: Function,
    action: Function,
    classes: Object,
    form: Object,
    fullScreen: boolean,
    history: Object,
    open: boolean,
}

type State = {
    number: string,
}

class TextMeTheApp extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            number: '',
        };
    }

    componentDidMount() {
        if (env('ENV') !== 'production') {
            window.textMe = this;
        }
    };

    close() {
        this.props.history.push('.');
    };

    updateNumber(value: string) {
        this.setState({ number: value });
    };

    componentDidUpdate() {
        if (this.props.form.type === 'TEXT_ME_THE_APP_SUCCEED') {
            this.close();
        }
    };

    render(): any {
        let { classes, fullScreen, open, action, form } = this.props;
        let { number } = this.state;

        let ReactPhoneInput = require('react-phone-input-2').default;

        let pending = (form.type === 'TEXT_ME_THE_APP_REQUESTED');

        return (
            <LocalesConsumer>
                {
                    ({translate}: ILocalesTransformers): React.Node => (
                        <Dialog
                            className={classes.root}
                            fullScreen={fullScreen}
                            open={open}
                            onClose={this.close.bind(this)}
                            aria-labelledby="Text me the app dialog"
                            onKeyDown={(event: Object) => {
                                (event.key === 'Enter') && action(number);
                            }}
                            tabIndex="0"
                            classes={{
                                paper: classes.paper,
                            }}
                        >
                            {pending
                                ? <Fade in={true}>
                                    <Grid
                                        item
                                        container
                                        direction={'column'}
                                        justifyContent={'flex-start'}
                                        alignItems={'center'}
                                        className={classes.wideLoader}
                                    >
                                        <Grid item>
                                            <CircularProgress color={'primary'}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='subheading'>
                                                {translate('modals.textme.send.sms')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Fade>
                                : <Fade in={true}>
                                    <React.Fragment>
                                        <Close
                                            data-automation={'close-button-forgot'}
                                            onClick={this.close.bind(this)}
                                        />
                                        <DialogTitle>
                                            {translate('modals.textme.android')}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                {translate('modals.textme.receive.sms')}
                                            </DialogContentText>
                                            <ReactPhoneInput
                                                value={number}
                                                containerClass={classnames(classes.phoneContainer, 'react-tel-input')}
                                                dropdownClass={classnames(classes.phoneDropdown, 'country-list')}
                                                inputClass={classnames(classes.phoneInput, 'country-list')}
                                                onChange={this.updateNumber.bind(this)}
                                                placeholder={translate('modals.textme.type.phone')}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                id={'wot-textme-button'}
                                                disabled={!number}
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={action.bind(this, this.state.number)}
                                            >
                                                {translate('general.textme.app')}
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                </Fade>
                            }
                        </Dialog>
                    )
                }
            </LocalesConsumer>
        );
    }
}

export default withMobileDialog()(withStyles(styles, { withTheme: true })(TextMeTheApp));
