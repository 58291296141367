// @flow
import type Node from 'react';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Modal as MuiModal} from '@material-ui/core';
import styles from './styles';

type ResultsModalProps = {
    classes?: Object,
    children: Node
}

const Modal = ({classes, children}: ResultsModalProps ): Node => {
    return (
        <MuiModal open={true} classes={{root: classes.root}} BackdropProps={{className: classes.background}}>
            {children}
        </MuiModal>
    );
};

export default withStyles(styles)(Modal);
