import styled from 'styled-components';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { FlexColumn, FlexColumnCentered, FlexRow, FlexRowCentered, mobileBreakPoint } from '../../../StyledUtils';
import { iconsPath } from '../../../utils/const';
import {DesktopButton} from '../../buttons/InstallWOT/StyledInstallWOT';

export const Header = styled(FlexColumnCentered)`
    align-items: center;
`;

export const Title = styled.span`
    font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    @media (max-width: ${mobileBreakPoint}){
        font-size: 18px;
    }
`;

export const Subtitle = styled.span`
    color: #545454;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 8px;
    text-align: center;
    max-width: 520px
`;

export const ResultsSection = styled(FlexColumn)`
    width: 100%;
    margin-top: ${({isFullPageFlow}) => isFullPageFlow ? 40: 24}px;
    margin-bottom:${({isFullPageFlow}) => isFullPageFlow ? 40: 16}px;
    & > *:not(:first-child) {
        margin-top: 16px;
    }
`;

export const SafeDescription = styled(FlexColumn)`
    align-items: center;
`;

export const FeaturesTitle = styled.span`
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    @media (max-width: ${mobileBreakPoint}){
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 22px;
    }
`;

export const FeaturesDescription = styled(FlexRow)`
    width: 100%;
    max-width: 420px;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 8px;
    @media (max-width: ${mobileBreakPoint}){
        width: auto;
        flex-direction: column;
        margin-bottom: 0;
    }
`;

export const FeatureRow = styled(FlexRow)`
    margin: 6px 0;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: ${mobileBreakPoint}){
        margin: 4px 0;
    }
`;

export const V = styled(CheckRoundedIcon)`
    margin-right: 10px;
    color: #16D478;
`;

export const RiskyDescription = styled(FlexRow)`
    box-sizing: border-box;
    align-items: start;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 12px 16px;
    border-radius: 8px;
    color: #545454;
    background: #FFE5E5;
    width: 100%;
    margin-top: 8px;
    @media (max-width: ${mobileBreakPoint}){
        margin-top: 0;
    }
`;

export const CtaContainer = styled(FlexRowCentered)`
    gap: 8px;
    padding-right: 16px;
    border-radius: 99px 16px 16px 99px;
    color: ${({ctaStyle}) => ctaStyle.color};
    background-color: ${({ctaStyle}) => ctaStyle.background};
    @media (max-width: ${mobileBreakPoint}) {
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        border-radius: 16px;
        text-align: center;
    }
    ${DesktopButton} {
        white-space: nowrap;
    }
`;

export const CtaContainerIcon = styled.div`
    height: 104px;
    width: 104px;
    flex-shrink: 0;
    background-size: contain;
    background-image: url("${({ctaStyle}) => `${iconsPath}/webHistoryResults/cta-${ctaStyle.trustLevel}.svg`}");
    @media (max-width: ${mobileBreakPoint}) {
        height: 64px;
        width: 64px;
    }
`;

export const CtaContainerDescription = styled.div`
    font-size: 16px;
    font-weight: 700;
`;

export const CtaContainerText = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #373B3E;
    @media (max-width: ${mobileBreakPoint}) {
        margin-top: 8px;
        font-size: 12px;
        line-height: normal;
    }
`;

export const WarningIcon = styled(WarningRoundedIcon)`
    margin: 0 8px;
    color: #FF4747;
    @media (max-width: ${mobileBreakPoint}){
        margin: 0;
    }
`;

export const DownloadSection = styled.div`
    margin-top: 24px;
    @media (max-width: ${mobileBreakPoint}){
        margin-top: 16px;
    }
`;
