// @flow
import React, {useEffect} from 'react';
import type Node from 'react';
import { useAuth, useDeviceInfo, useInternalAnalytics } from '../../hooks';
import type { AnalyticsSender } from '../../hooks/useInternalAnalytics';
import { AnalyticsProvider } from '../../components/AnalyticsProvider/AnalyticsProvider';
import Header from '../../blocks/header';
import Footer from '../../blocks/footer';
import {FeaturesSection} from './FeaturesSection/FeaturesSection';
import {PremiumSection} from './PremiumSection/PremiumSection';
import ReviewsSection from './ReviewsSection/ReviewsSection';
import {StyledFooterSection, StyledHomepageContainer} from './StyledHomepage';
import {HistorySection} from './HistorySection';

export default function HomePage(): Node {
    const {isMobile, lang} = useDeviceInfo();
    const auth = useAuth();
    const sendAnalytics: AnalyticsSender = useInternalAnalytics();

    useEffect(() => {
        sendAnalytics({type: 'HOMEPAGE_VIEW'});
    }, []);

    return (
        <StyledHomepageContainer>
            <Header {...{isMobile, lang, user: auth}}/>
            <HistorySection/>
            <FeaturesSection/>
            <PremiumSection/>
            <ReviewsSection/>
            <StyledFooterSection>
                <Footer {...{isMobile, lang}}/>
            </StyledFooterSection>
        </StyledHomepageContainer>
    );
};
