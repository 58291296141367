import React from 'react';
import styled from 'styled-components';
import {FlexRow, FlexRowCentered, mobileBreakPoint} from '../../../../StyledUtils';
import {GET_FAVICON_PREFIX, iconsPath} from '../../../../utils/const';

export const ResultsBox = styled(FlexRow)`
    cursor: pointer;
    align-items: center;
    border-radius: 16px;
    background: white;
    width: 100%;
    box-shadow: 0 2px 1px -2px rgba(0, 0, 0, 0.06), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 10px 20px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid ${({isFullPageFlow}) => isFullPageFlow ? '#CCD1D7' : 'white'};
    &:hover {
        border: 1px solid ${({borderHoverColor}) => borderHoverColor};
    }
    @media (max-width: ${mobileBreakPoint}){
        min-width: unset;
    }
`;

export const ResultsDetailsBox = styled(FlexRow)`
    align-items: start;
    padding: 12px;
    flex-grow: 2;
`;

export const ResultsIcon = styled.div`
    flex-shrink: 0;
    align-self: start;
    height: 32px;
    width: 32px;
    background-image: url("${({iconSrc}) => `${iconsPath}/webHistoryResults/${iconSrc}.svg`}");
    background-size: contain;
    @media (max-width: ${mobileBreakPoint}){
        height: 24px;
        width: 24px;
    }
`;

export const ResultsDetails = styled.div`
    width: 100%;
    margin-left: 16px;
    @media (max-width: ${mobileBreakPoint}){
        margin-left: 12px;
    }
`;

export const ResultsTitle = styled.span`
    font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: ${mobileBreakPoint}){
        font-size: 14px;
    }
`;

export const ResultsBar = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
    height: 8px;
    border-radius: 4px;
    background: ${({barColor}) => barColor};
    width: ${({Width}) => Width && Width - 5}%;
    @media (max-width: ${mobileBreakPoint}){
        margin-top: 8px;
        height: 4px;
    }
`;

export const SitesDetailsRow = styled(FlexRowCentered)`
    color: #545454;
    font-size: 14px;
    @media (max-width: ${mobileBreakPoint}){
        font-size: 12px;
    }
`;

export const FavIcons = styled(FlexRow)`
    margin-right: 8px;
    & > *:not(:first-child) {
        margin-left: -12px;
        &:before {
            filter: blur(2px);
        }
    }
    ${({children}) => {
    let zIndexes = '';
    const count = React.Children.count(children);
    for (let i = 1; i <= count; i++) {
        zIndexes += `& > *:nth-child(${i}){
                            z-index: ${count - i + 1};
                         }`;
    }
        return zIndexes;
    }};
    @media (max-width: ${mobileBreakPoint}){
        display: none;
    }
}
`;

export const FavIcon = styled.div`
    border-radius: 50%;
    border: 1px solid ${({isAdult}) => isAdult ? '#FF4747' : 'white'};
    width: 22px;
    background-color: white;
    height: 22px;
    overflow: hidden;
    &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif;
        box-sizing: border-box;
        color: ${({isAdult}) => isAdult ? '#FF4747' : 'transparent'};
        background-image: ${({isAdult, domainSrc}) => !isAdult && domainSrc ? `url("${GET_FAVICON_PREFIX}${domainSrc}&sz=32")` : null};
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        content: '18+';
        font-size: 11px;
        font-weight: 700;
        line-height: 2px;
    }
`;

export const MostVisitedSite = styled.span`
    text-decoration: underline;
    font-weight: 400;
    max-width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const MoreSites = styled.span`
    font-weight: 600;
    margin-left: 4px;
`;

export const SitesCounter = styled.span`
    align-self: center;
    margin-left: auto;
    text-align: end;
    font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif;
    font-size: 28px;
    font-weight: 600;
    padding-right: 32px;
    min-width: ${({minWidth}) => minWidth || 3}ch;
    @media (max-width: ${mobileBreakPoint}){
        font-size: 20px;
        padding-right: 16px;
    }
`;
