// @flow
import styled, { css } from 'styled-components';
import {HEADER_HEIGHT} from '../../layouts/theme';
import { mobileBreakPoint, FlexRow, FlexColumn, mobileMediaQuery } from '../../StyledUtils';
import Toolbar from '@material-ui/core/Toolbar';
import {SearchIcon} from '../../components/SearchBar/StyledSearchBar';
import {InstallButtonContainerRow} from '../../components/buttons/InstallContainer/StyledInstallContainer';
import {langPrefix} from '../../utils';

export const ToolbarContainer = styled(FlexRow)`
    width: 100%;
    justify-content: center;
`;

export const StyledToolbar = styled(Toolbar)`
    padding: 0 18px !important;
    max-width: 100%;
    border-bottom: ${({withHeaderBorder}: Object): any => withHeaderBorder ? '1px solid #DEDEDE': null};
    @media (max-width: ${mobileBreakPoint}) {
      box-shadow: ${({withHeaderBorder}: Object): any => withHeaderBorder ? null : '0 3px 10px -1px #DEDEDE'};
    }
`;

export const HeaderContainer = styled.div`
  box-shadow: none;
  @media (max-width: ${mobileBreakPoint}) {
    & header {
      top: ${({isHidden, isCookiesConsentVisible}: Object): string => `${(+!isHidden && HEADER_HEIGHT) + (+!!isCookiesConsentVisible && HEADER_HEIGHT) - HEADER_HEIGHT}px`};
      transition: top 0.3s ease-in-out;
    }
  }
  
  #downshift-0-input-input {
    background-color: transparent;
  }

  #buttonLink {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #373B3E;
  }
`;

export const Root = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  height: ${HEADER_HEIGHT}px;
  ${mobileMediaQuery(css`
      height: ${({isCookiesConsentVisible}: Object): number => isCookiesConsentVisible ? HEADER_HEIGHT * 2: HEADER_HEIGHT}px;
      transition: height 0.3s ease-in-out;
  `)}
`;

export const Flex = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const FlexMobile = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0px 22px;
    border-bottom: 1px solid #d6d6d6;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    min-width: 200px;
    width: calc(100% + 16px);
    background-color: #fafafa;
`;

export const FlexRight = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const AlignLeft = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const FlexFullWidth = styled.div`
  display: flex;
  width: 100%;
`;

export const AlignRight = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    color: #929292;
    line-height: 1.43;
    text-transform: capitalize;
    width: 100%;
    align-items: center;
    [data-automation="desktop-menu-item"]{
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        color: #373B3E;
        line-height: 1.43;
        text-transform: capitalize;
        border-radius: 999px;
        &:hover{
            color: #252525;
            background-color: #F1F7FD;
        }
    }
    ${InstallButtonContainerRow} {
      padding: 0 16px;
    };
    #wot-install-button {
        height: 40px;
        border-radius: 24px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: #037AFF;
        color: #fff;
        margin: -1px 0 0 ;
        padding: 0 16px;
        font-size: 16px;
        text-transform: none;
        align-self: center;
        &:hover {
          background-color: #036bdf;
        }
    };
`;

export const InstallWotPlaceHolder = styled.div`
    margin: 0 16px;
`;

export const AppBarOnTop = styled.div`
    z-index: 1301;
`;

export const Logo = styled.a.attrs(({lang}: Object): Object => ({
    'alt': 'WOT Logo',
    'href': lang && lang !== 'en-us' ? langPrefix({lang}): '/',
    'id': 'wot-header-logo',
    'data-automation': 'desktop-logo',
}))`
    width: 70px;
    min-width: 70px;
    height: 39px;
    cursor: pointer;
    font-size: 0;
    margin-top: 11px;
    margin-right: 25px;
    box-sizing: border-box;
    background-size: 70px;
    background-image: url(/images/logo.svg);
    background-repeat: no-repeat;   
    
    @media (max-width: ${mobileBreakPoint}){
        margin-right: 0px;
        margin-top: 15px;
    }
`;

export const LinkText = styled.span`
  font-family: ProzaLibre;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: right;
  color: #929292;
`;

export const ButtonLink = styled.div`
    &[data-automation="desktop-menu-item"]{
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        color: #929292;
        line-height: 1.43;
        text-transform: none;
        &:hover{
            color: #252525; 
            border-radius: 4px;
            background-color: #f6f6f6;
        }
    }
`;

export const LogInButton = styled.div`
    width: 119px;
    height: 40px;
    display: table;
    text-align: center;
    margin-top: 9px;
    margin-bottom: 10px;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 4px;
    background-color: transparent;
    box-sizing: border-box;
    &:hover{
        box-shadow: 0 0 8px 0 rgba(53, 90, 60, 0.3);
        background-color: #036bdf;
    }
`;

export const LogIn = styled.span`
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #037AFF;
    &:hover {
      color: #FFFFFF
    }
`;

export const InvisibleLoginForExternalTrigger = styled.span`
    display: none;
`;

export const MenuItemsContainer = styled(FlexRow)`
    align-items: center;
    justify-content: center;
`;

export const MenuItemIconContainer = styled.div`
    background-color: rgba(38, 38, 38, 0.1);
    border-radius: 50%;
    padding: 8px;
    display: flex;
    justify-content: center;
`;

export const MenuItemIcon = styled.img.attrs({
    alt: 'WOT Review Stars'
})`
    width: 48px;
    height: 48px;
`;

export const MenuItemTextContainer = styled(FlexColumn)`
    justify-content: center;
`;

export const MenuItemTitle = styled.div`
    margin: 2px;
    padding-left: 16px;
    font-size: 16px;
    color: #383C40;
    line-height: 16px;
`;

export const MenuItemSubtitle = styled.div`
    margin: 2px;
    padding-left: 16px;
    font-size: 14px;
    color: #929CA6;
    line-height: 16.41px;
`;


// Homepage Searchbar Styling

export const SearchBarHomepageRoot = styled.div`
  display: flex;
  justify-content: center;
`;

export const SearchBarHomepageContainer = styled(FlexRow)`
  height: 40px;
  align-items: center;
  border-radius: ${({dropdownOpen}: any): string => dropdownOpen ? '12px 12px 0px 0px' : '999px'}; 
  justify-content: center;
  border: ${({dropdownOpen}: any): string => dropdownOpen ? 'none' : '1px solid #929CA5'};
  box-shadow: ${({dropdownOpen}: any): string => dropdownOpen ? '0px 7px 25px rgb(0 0 0 / 25%)' : 'none'};
  &:hover {
    border: ${({dropdownOpen}: any): string => dropdownOpen ? 'none' : '1px solid #057AFF'};
  }
`;

export const SearchBarHomepageDropdownInput = styled(SearchBarHomepageContainer)`
  box-shadow: 0px 7px 25px rgb(0 0 0 / 25%);  
`;

export const SearchButtonHomepage = styled.div`
  display: flex;
  cursor: pointer;
  @media (max-width: ${mobileBreakPoint}) {
    padding-right: 10px;
  }
`;

export const SearchBarIconHomepage = styled(SearchIcon)`
  margin: 12px 3px;
  background-image: url(/images/icons/search-blue.svg);
`;

export const SearchBarHomepageDropdownIcon = styled(SearchIcon)`
  margin: 12px 0px;
`;
