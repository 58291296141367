// @flow
import configuration from '../../configuration';
import merge from 'lodash/merge';
import { isNode } from '../utils/isnode';

export default (envKey: Array<string> | string): any => {
    let pointer: Object = merge({}, isNode() ? process.env : {}, { configuration });

    if (Array.isArray(envKey)) {
        for (let key of envKey) {
            if (pointer.hasOwnProperty(key)) {
                pointer = pointer[key];
            } else {
                return {};
            }
        }
    } else {
        pointer = pointer[envKey];
    }
    return pointer;
};
