// @flow
import React from 'react';
import type Node from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '../../hooks';
import { write } from '../../utils/cookie';
import { CLOSE_DISCLAIMER_KEY, CLOSE_DISCLAIMER_VALUE } from '../../utils/const';
import ReactGA from '../../utils/ga';
import { CookiesConsentContainer, Description, CTA, Link } from './StyledCookiesConsent';

export const CookiesConsent = (): Node => {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const closeCookiesConsent = () => {
        write(CLOSE_DISCLAIMER_KEY, CLOSE_DISCLAIMER_VALUE, ';Path=/;Expires=Fri, 01 Jan 2038 00:00:01 GMT');
        ReactGA.event({ category: 'Cookies_Consent', action: 'Accept' });
        dispatch({ type: 'COOKIES_ACCEPTED' });
    };

    return (
        <CookiesConsentContainer data-automation='close-cookies-consent'>
            <Description>
                {translate('components.cookies-consent.desc')}
                <Link rel='noopener' href={'https://www.mywot.com/privacy#cookies-&-third-party-tools'} target='_blank'>
                    {translate('components.cookies-consent.link')}
                </Link>
            </Description>
            <CTA onClick={closeCookiesConsent}>
                {translate('components.cookies-consent.button')}
            </CTA>
        </CookiesConsentContainer>
    );
};
