// @flow

import * as React from 'react';
import merge from 'lodash/merge';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { validateEmail } from '../../../utils/index';


import Close from '../../../components/buttons/close/index';
import { CommonInput } from '../../../components/inputs/index';
import styles from './styles';
import env from '../../../utils/env';
import ReactGA from '../../../utils/ga';
import {LocalesConsumer} from '../../../utils/locales';
import type {ILocalesTransformers} from '../../../utils/locales';

type Props = {
    acknowledge: Function,
    action: Function,
    auth: Object,
    lastAction: Object,
    classes: Object,
    fullScreen: boolean,
    history: Object,
    onClose: Function,
    open: boolean,
    theme: Object,
}

type Field = {
    message: ?string,
    valid: boolean,
    value: string
}


type State = {
    login: Field,
    open: boolean,
}

class Forgot extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: this.props.open || false,
            login: { value: '', valid: true, message: '' },
        };
    }

    open() {
        this.setState({ open: true });
    };

    close(value: ?any) {
        this.setState({ open: false });
        this.props.history.push('.');
        setTimeout(() => {
            this.props.onClose && this.props.onClose(value);
        }, 400);
    };

    componentDidMount() {
        ReactGA.pageview('/forgot');

        if (env('ENV') !== 'production') {
            window.forgot = this;
        }
        // setTimeout(() => {
        //     let event = new Event('click');
        //     for (let input of document.querySelectorAll('input')) {
        //         input.dispatchEvent(event);
        //     }
        // }, 2000)
    }

    componentDidUpdate() {
        let succeed = Boolean(this.props.lastAction.type && this.props.lastAction.type === 'FORGOT_PASS_SUCCEED');

        if (succeed) {
            this.close();
        }
    }

    render(): any {
        const { classes, fullScreen, auth, acknowledge, action } = this.props;
        const { login } = this.state;

        let pending = Boolean(auth.status && auth.status.indexOf('REQUESTED') !== -1);
        let failed = Boolean(auth.status && auth.status.indexOf('FAILED') !== -1);

        return (
            <LocalesConsumer>
                {
                    ({translate}: ILocalesTransformers): React.Node => (
                        <Dialog
                            fullScreen={fullScreen}
                            open={this.state.open}
                            onClose={this.close.bind(this)}
                            aria-labelledby="Forgot-password modal"
                            classes={{ root: classes.root }}
                            // onKeyDown={(event: Object) => {
                            //     (event.key === 'Enter') && action(login.value);
                            // }}
                            tabIndex="0"
                            PaperProps={{
                                'data-automation': 'dialog-forgot-paper',
                            }}
                        >
                            <Close
                                data-automation={'close-button-forgot'}
                                onClick={this.close.bind(this)}
                            />
                            <div className={classes.title}>
                                <img
                                    src="/images/logo.png"
                                    alt="myWOT logo"
                                    className={classes.logo}
                                    data-automation="logo"/>
                                <DialogTitle variant="title" data-automation="title">
                                    {translate('modals.forgot.new.password')}
                                </DialogTitle>
                            </div>
                            {
                                pending && (
                                    <div className={classes.loader}>
                                        <CircularProgress className={classes.progress} size={100}/>
                                    </div>
                                )
                            }
                            <DialogContent className={classes.content}>
                                <Grid
                                    container
                                    item
                                    spacing={1}
                                    className={classes.fields}
                                    validate="true"
                                >
                                    <Grid item>
                                        <CommonInput
                                            label={translate('general.email')}
                                            placeholder={translate('general.email')}
                                            helperText={login.message}
                                            error={failed || !login.valid}
                                            autoComplete={'email'}
                                            data-automation={'email'}
                                            value={login.value}
                                            inputClass={classes.modalInput}
                                            onKeyDown={(event: Object) => {
                                                if (event.which === 13) {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    const result = validateEmail(login.value, translate);

                                                    this.setState((state: State): Object => ({
                                                        login: merge(state.login, {
                                                            valid: result.status,
                                                            message: result.message,
                                                        }),
                                                    }));

                                                    if (result.status) {
                                                        action(login.value);
                                                    }
                                                }
                                            }}
                                            onChange={(event: Object) => {
                                                let value = event.target.value || '';
                                                failed && acknowledge && acknowledge();
                                                this.setState((state: State): Object => ({
                                                    login: merge(state.login, { value, valid: true, message: '' }),
                                                }));
                                            }}
                                            onBlur={(event: Object) => {
                                                let result = validateEmail(this.state.login.value, translate);
                                                this.setState((state: State): Object => ({
                                                    login: merge(state.login, { valid: result.status, message: result.message }),
                                                }));
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.actions}>
                                <Button
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}
                                    color="default"
                                    data-automation={'go-back-button'}
                                >
                                    <ArrowBack data-automation="arrow"/>
                                    {translate('modals.forgot.go.back')}
                                </Button>
                                <Button
                                    onClick={action.bind(this, login.value)}
                                    color="primary"
                                    variant="contained"
                                    disabled={!validateEmail(login.value, translate).status}
                                    id={'wot-forgot-button'}
                                    data-automation={'wot-forgot-button'}
                                >
                                    {translate('modals.forgot.send.email')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </LocalesConsumer>
        );
    }
}

export default withMobileDialog()(withStyles(styles, { withTheme: true })(Forgot));
