// @flow
import React, {useContext, useState} from 'react';
import ReactGA from '../../utils/ga';
import {CircleIcon, TagName, TagWrapper, Tag, TagsContainer, TrailerLine} from './StyledTags';
import type {ILocalesTransformers} from '../../utils/locales';
import {LocalesContext} from '../../utils/locales';

export type ITagsProps = {
    tagsToMap: Object;
    iconPath: string;
    selectedIconPath?: string;
    trackingCategory?: string;
    trackingAction?: string;
}

export const Tags = ((props: ITagsProps): any => {
    const {tagsToMap, iconPath, selectedIconPath, trackingCategory, trackingAction} = props;
    const {translate}: ILocalesTransformers = useContext(LocalesContext);
    // const [selectedItem, setSelectedItem] = useState(0);
    // const onItemHover = (itemNumber: number) => {
    //     ReactGA.event({ category: trackingCategory, action: trackingAction, label: 'Selected_item_' + itemNumber});
    //     setSelectedItem(itemNumber);
    // };
    // const onItemLeave = () => {
    //     setSelectedItem(0);
    // };

    return (
        <TagsContainer>
            { tagsToMap.map((tag: Object, index: number): any => (
                <TagWrapper key={tag.name} >
                    {/* <Tag onMouseOver={(): void => onItemHover(index+1)} onMouseLeave={onItemLeave} selectedItem={selectedItem === index+1}>*/}
                    {/*    <CircleIcon selectedItem={selectedItem === index+1} iconPath={iconPath} selectedIconPath={selectedIconPath}/>*/}
                    {/*    <TagName selectedItem={selectedItem === index+1}>{translate(tag.name)}</TagName>*/}
                    {/* </Tag>*/}
                    <Tag>
                        <CircleIcon iconPath={iconPath}/>
                        <TagName>{translate(tag.name)}</TagName>
                    </Tag>
                </TagWrapper> ))}
        </TagsContainer>
    );
});
