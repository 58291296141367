// @flow
import type Node from 'react';
import React, {useMemo} from 'react';
import {ThemeProvider} from 'styled-components';
import {APP_STORE_UTM, GOOGLE_PLAY_STORE} from '../../../../utils/const';
import {getUtmString, saveUtmToCookies} from '../../../../utils';
import {useDeviceInfo, useEnabledFeatureAnalytics, useFeatureIsOn, useTranslate} from '../../../../hooks';
import type {WebHistoryCategoryResults, SiteDetails} from '../ResultsPage';
import {
    FavIcon, FavIcons, MoreSites, MostVisitedSite,
    ResultsBar, ResultsBox, ResultsDetails, ResultsDetailsBox,
    ResultsIcon, ResultsTitle, SitesCounter, SitesDetailsRow
} from './StyledCategoryResults';

type CategoryResultsProps = {
    color: string,
    iconType: string,
    title: string,
    results: WebHistoryCategoryResults,
    highestCounter: number
}

type SiteEntry = [string, SiteDetails];

type SiteData = {
    domain: string,
    isAdult: boolean
}

function compareByMostVisited(a: [string, SiteDetails], b: [string, SiteDetails]): boolean {
    return b[1].visitCount - a[1].visitCount;
}

function prepareSiteDataForUi(siteEntry: [string, SiteDetails]): SiteData {
    return {
        domain: siteEntry[0],
        isAdult: !!siteEntry[1]?.isAdult
    };
}

const googlePlayStore = GOOGLE_PLAY_STORE.concat(`&${getUtmString()}`);
const appleAppStore = `${APP_STORE_UTM}&${getUtmString()}`;

export const CategoryResults = ({color, iconType, title, results, highestCounter}: CategoryResultsProps): Node => {
    const {isMobile, isAndroid, isIos, isExtInstalled} = useDeviceInfo();
    const translate = useTranslate();
    const sendAnalytics = useEnabledFeatureAnalytics();
    const isFullPageFlow = useFeatureIsOn('google-history-oauth-full-page-flow');
    const {total, sites} = results;
    const topVisitedSites = useMemo((): SiteData[] => {
        const sitesVisitCountEntries: SiteEntry[] = Object.entries(sites);
        if (sitesVisitCountEntries.length > 1) {
            sitesVisitCountEntries.sort(compareByMostVisited);
        }
        return sitesVisitCountEntries.slice(0, 4).map(prepareSiteDataForUi);
    }, [results]);

    const getLink = (): string => {
        switch (true) {
            case isIos:
                return appleAppStore;
            case isAndroid:
                return googlePlayStore;
            default:
                return `/download?${getUtmString()}`;
        }
    };

    const onResultClick = () => {
        if (isExtInstalled) {
            return;
        }
        sendAnalytics({type: 'GO_TO_STORE_CTA'});
        saveUtmToCookies();
        location.href = getLink();
    };

    return (
        <ThemeProvider theme={{isMobile}}>
            <ResultsBox borderHoverColor={color} isFullPageFlow={isFullPageFlow} onClick={onResultClick}>
                <ResultsDetailsBox>
                    <ResultsIcon iconSrc={iconType}/>
                    <ResultsDetails>
                        <ResultsTitle>{title}</ResultsTitle>
                        <ResultsBar barColor={color} Width={Math.ceil(100 / highestCounter * total)}/>
                        <SitesDetailsRow>
                            <FavIcons>
                                {topVisitedSites.map(({domain, isAdult}: SiteData, index: number): Node =>
                                    <FavIcon key={index} domainSrc={domain} isAdult={isAdult}/>
                                )}
                            </FavIcons>
                            <MostVisitedSite>{topVisitedSites[0]?.domain}</MostVisitedSite>
                            {total - 1 > 0 && <MoreSites>{`+${total - 1} ${translate('modals.webHistory.results.moreSites')}`}</MoreSites>}
                        </SitesDetailsRow>
                    </ResultsDetails>
                </ResultsDetailsBox>
                <SitesCounter minWidth={`${highestCounter}`.length}>{total}</SitesCounter>
            </ResultsBox>
        </ThemeProvider>
    );
};
