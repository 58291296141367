import {zIndexModal} from '../../StyledUtils';

export default () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: `${zIndexModal}`,
        '& > :focus-visible': {
            outline: 'none'
        }
    },
    background: {
        background: 'rgba(0,0,0,.6)'
    }
});
