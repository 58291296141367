// @flow
import * as React from 'react';
import merge from 'lodash/merge';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MediaQuery from 'react-responsive';
import type { Node } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import styles from './styles';
import menu from './menu.json';
import env from '../../utils/env';
import {dropdownLanguages, dropdownLangsPossibilities, HELP_CENTER_URL_GUIDELINES, BROWSER_STORES_LINKS} from '../../utils/const';
import {write} from '../../utils/cookie';
import type {ILocalesTransformers, ITranslate} from '../../utils/locales';
import {LocalesConsumer} from '../../utils/locales';
import ReactGA from '../../utils/ga';
import InstallButtonContainer from '../../components/buttons/InstallContainer/InstallButtonContainer';
import {
    FooterContainer, FooterWrapper, BottomRow, Logo, SeparatorLine, MenuContainer, MenuTitle,
    LanguageFlag, LanguageSelected, LinksContainer, StyledLink, SocialAndCopyrightContainer, CopyrightText, TopRow,
    SeparatorDot, SeparatorColumn, AlsoAvailableOnContainer, BrowserIcon, IconCircle
} from './StyledFooter';
import {FlexColumn, mobileBreakPointInt} from '../../StyledUtils';
import {pathWithLang} from '../../utils';

type Props = {
    classes: Object,
    history: Object,
    lang: string,
    theme: Object,
    isMobile: boolean,
    forceMobile?: boolean
}

type State = {
    hasMount: boolean,
    langs: Object,
    menu: Array<Object>
}

const iconsPath = '/images/icons';
const browsersIcons = [
    {
        path: 'chrome-grey.svg',
        link: BROWSER_STORES_LINKS.chrome,
        event: {category: 'Footer', action: 'BrowserLink', label: 'chrome'}
    },
    {
        path: 'firefox-grey.svg',
        link: BROWSER_STORES_LINKS.firefox,
        event: {category: 'Footer', action: 'BrowserLink', label: 'firefox'}
    },
    {
        path: 'edge-grey.svg',
        link: BROWSER_STORES_LINKS.edge,
        event: {category: 'Footer', action: 'BrowserLink', label: 'edge'}
    },
    {
        path: 'opera-grey.svg',
        link: BROWSER_STORES_LINKS.opera,
        event: {category: 'Footer', action: 'BrowserLink', label: 'opera'}
    },
    {
        path: 'safari-grey.svg',
        link: BROWSER_STORES_LINKS.safari,
        event: {category: 'Footer', action: 'BrowserLink', label: 'safari'}
    }];
const mobileIcons = [
    {
        path: 'android-grey.svg',
        link: BROWSER_STORES_LINKS.android,
        event: {category: 'Footer', action: 'BrowserLink', label: 'android'}
    },
    {
        path: 'ios-grey.svg',
        link: BROWSER_STORES_LINKS.iOS,
        event: {category: 'Footer', action: 'BrowserLink', label: 'ios'}
    }];
class Footer extends React.PureComponent<Props, State> {
    anchorEl;
    constructor(props: Object) {
        super(props);

        const initialLang: Object = dropdownLanguages.find((lang: Object): Object => lang.key.startsWith(props.lang));

        this.state = {
            hasMount: false,
            menu,
            langs: {
                anchor: null,
                current: initialLang ? initialLang.key : dropdownLanguages[0].key, // en-us
                options: dropdownLanguages,
                open: false,
            },
        };
    }

    componentDidMount() {
        if (env('ENV') !== 'production') {
            window.footer = this;
        }

        this.setState({ hasMount: true });
    }

    render(): any {
        let { classes, isMobile, lang} = this.props;

        const onBrowserIconClick = (iconObject: Object): any => {
            const { link, event } = iconObject;
            ReactGA.event(event);
        };

        const desktop = (translate: ITranslate): React.Node => (
            <Grid container data-automation={'footer-wrapper'} className={classes.container}>

                <TopRow>
                    <FlexColumn>
                        <Logo id={'wot-footer-logo'} data-automation={'footer-logo'} lang={lang}/>
                        {this.renderLanguage.bind(this)()}
                        {this.renderSocialLinks()}
                    </FlexColumn>
                    {this.renderMenu(translate)}
                </TopRow>
                <BottomRow style={{zIndex: 2}}>
                    <LinksContainer>
                        <StyledLink href={'/privacy'} id={`wot-footer-link-privacy`} data-ga-label={'privacy-policy'} data-automation={'footer-link-privacy'}>
                            {translate('components.footer.privacy')}
                        </StyledLink>
                        <SeparatorDot />
                        <StyledLink href={'/extension-privacy'} id={`wot-footer-link-extension-privacy`} data-ga-label={'extension-privacy'} data-automation={'footer-link-extension-privacy'}>
                            {translate('components.footer.extension-privacy')}
                        </StyledLink>
                        <SeparatorDot />
                        <StyledLink href={'/terms'} id={`wot-footer-link-terms`} data-ga-label={'terms-of-use'} data-automation={'footer-link-terms'}>
                            {translate('components.footer.terms')}
                        </StyledLink>
                        <SeparatorDot />
                        <StyledLink href={HELP_CENTER_URL_GUIDELINES} id={`wot-footer-link-guidelines`} data-ga-label={'guidelines'} data-automation={'footer-link-guidelines'}>
                            {translate('components.footer.guidelines')}
                        </StyledLink>
                    </LinksContainer>
                    <SeparatorDot />
                    <SocialAndCopyrightContainer>
                        <CopyrightText>{translate('components.footer.copyright')}</CopyrightText>
                    </SocialAndCopyrightContainer>
                    <AlsoAvailableOnContainer>
                        <CopyrightText>{translate('pages.desktop.landing.headline.install')}:</CopyrightText>
                        {browsersIcons.map((iconObject: Object): Node => <BrowserIcon href={iconObject.link} key={iconObject.path} imgPath={`${iconsPath}/browsers/${iconObject.path}`} onClick={(): void => onBrowserIconClick(iconObject)}/>)}
                        <SeparatorColumn className={classes.bottomRowSeparator}/>
                        {mobileIcons.map((iconObject: Object): Node => <BrowserIcon href={iconObject.link} key={iconObject.path} imgPath={`${iconsPath}/browsers/${iconObject.path}`} onClick={(): void => onBrowserIconClick(iconObject)}/>)}
                    </AlsoAvailableOnContainer>
                </BottomRow>
            </Grid>
        );

        const mobile = (translate: ITranslate): React.Node => (
            <FooterWrapper>

                <Logo id={'wot-footer-logo'} data-automation={'footer-logo'} lang={lang}/>

                {this.renderLanguage.bind(this)()}
                {this.renderSocialLinks()}

                <SeparatorLine />

                <InstallButtonContainer trackingCategory={'Footer'} trackingAction={'Mobile View'} wotProductSource={'Footer_Mobile_View'} round />

                <SeparatorLine />

                {this.renderMenu(translate)}

                <CopyrightText>{translate('components.footer.copyright')}</CopyrightText>

                <LinksContainer>
                    <StyledLink href={'/privacy'} id={`wot-footer-link-privacy`} data-ga-label={'privacy-policy'} data-automation={'footer-link-privacy'}>
                        {translate('components.footer.privacy')}
                    </StyledLink>
                    <StyledLink href={'/terms'} id={`wot-footer-link-terms`} data-ga-label={'terms-of-use'} data-automation={'footer-link-terms'}>
                        {translate('components.footer.terms')}
                    </StyledLink>
                    <StyledLink href={HELP_CENTER_URL_GUIDELINES} id={`wot-footer-link-guidelines`} data-ga-label={'guidelines'} data-automation={'footer-link-guidelines'}>
                        {translate('components.footer.guidelines')}
                    </StyledLink>
                </LinksContainer>
            </FooterWrapper>
        );

        return (
            <LocalesConsumer>
                {
                    ({translate}: ILocalesTransformers): React.Node => (
                        <Grid container className={classes.root} id={'footerContainer'}>
                            {this.state.hasMount ? (
                                <FooterContainer>
                                    <MediaQuery minWidth={mobileBreakPointInt+1}>
                                        {desktop(translate)}
                                        <BottomRow />
                                    </MediaQuery>
                                    <MediaQuery maxWidth={mobileBreakPointInt}>
                                        {mobile(translate)}
                                    </MediaQuery>
                                </FooterContainer>
                            ) : (
                                <FooterContainer>
                                    {isMobile ? mobile(translate) : desktop(translate)}
                                </FooterContainer>
                            )}
                        </Grid>
                    )
                }
            </LocalesConsumer>
        );
    }

    renderMenu(translate: ITranslate): any {
        let { classes, isMobile } = this.props;
        return (
            <MenuContainer>
                {this.state.menu.map((title: Object, index: number): Node => {
                    return (
                        isMobile && title.hideInMobile ? <React.Fragment key={title.name}/> :
                            <Grid item key={title.name}>
                                <span
                                    id={`wot-footer-menu-${title.name}-title`}
                                    data-ga-label={title.name}
                                    data-automation={'title-item'}
                                >
                                    <MenuTitle>{translate(title.locale)}</MenuTitle>
                                </span>
                                <span>
                                    {
                                        title.subs.map((subsDetails: Object, index: number): Node => {
                                            return (
                                                <a
                                                    key={index}
                                                    className={classes.menuLink}
                                                    href={subsDetails.url}
                                                    id={`wot-footer-menu-${subsDetails.name}`}
                                                    data-ga-label={subsDetails.name}
                                                    data-automation={'link-item'}
                                                >
                                                    <Typography className={classes.menuText}>
                                                        {translate(subsDetails.locale)}
                                                    </Typography>
                                                </a>
                                            );
                                        })
                                    }
                                </span>
                                {index === this.state.menu.length - 1 && <InstallButtonContainer trackingCategory={'Footer'} trackingAction={'Desktop View'} wotProductSource={'Footer_Desktop_View'} round/>}
                                {isMobile && index !== this.state.menu.length - 2 && <SeparatorLine />}
                            </Grid>
                    );
                })}
            </MenuContainer>
        );
    }

    renderSocialLinks(): Node {
        let { classes } = this.props;
        const iconSize = '24px';
        return (
            <Grid item className={classes.socLinks} data-automation={'social-media-pages'}>
                <IconCircle>
                    <a style={{height: iconSize}} rel='noopener' href={'http://www.facebook.com/weboftrust'} data-automation={'social-page-facebook'} id={`wot-footer-social-facebook`} data-ga-label={'Facebook'}>
                        <img style={{marginLeft: 0}} width={iconSize} height={iconSize} className={classes.socIcon} src={`${iconsPath}/socLinks/facebook-grey.svg`} alt="facebook" />
                    </a>
                </IconCircle>
                <IconCircle>
                    <a style={{height: iconSize}} rel='noopener' href={'https://twitter.com/Web_of_Trust'} data-automation={'social-page-twitter'} id={`wot-footer-social-twitter`} data-ga-label={'Twitter'}>
                        <img width={iconSize} height={iconSize} className={classes.socIcon} src={`${iconsPath}/socLinks/twitter-grey.svg`} alt="twitter" />
                    </a>
                </IconCircle>
                <IconCircle>
                <a style={{height: iconSize}} rel='noopener' href={'https://www.youtube.com/user/MyWOT'} data-automation={'social-page-youtube'} id={`wot-footer-social-youtube`} data-ga-label={'Youtube'}>
                    <img width={iconSize} height={iconSize} className={classes.socIcon} src={`${iconsPath}/socLinks/youtube-grey.svg`} alt="youtube" />
                </a>
                </IconCircle>
                <IconCircle>
                    <a style={{height: iconSize}} rel='noopener' href={'https://www.linkedin.com/company/wot-services-ltd.'} data-automation={'social-page-linkedIn'} id={`wot-footer-social-linkedin`} data-ga-label={'Linkedin'}>
                        <img width={iconSize} height={iconSize} className={classes.socIcon} src={`${iconsPath}/socLinks/linkedin-grey.svg`} alt="linkedin" />
                    </a>
                </IconCircle>
                <IconCircle>
                    <a style={{height: iconSize}} rel='noopener' href={'https://medium.com/mywot-web-of-trust'} data-automation={'social-page-medium'} id={`wot-footer-social-medium`} data-ga-label={'Medium'}>
                        <img width={iconSize} height={iconSize} className={classes.socIcon} src={`${iconsPath}/socLinks/medium-grey.svg`} alt="medium" />
                    </a>
                </IconCircle>
            </Grid>
        );
    }

    changeLang = (lang: string) => {
        const domain = location.hostname.startsWith('www.') ? location.hostname.substr(3): location.hostname;
        write('lang', lang, `;Path=/;Domain=${domain}`); // lang cookie is always on path '/'
        this.setState({langs: merge({}, this.state.langs, { open: false, current: lang })});
        location.href = `${location.origin}${pathWithLang(location.pathname + location.search, {lang})}`;
    }

    renderLanguage(): Node {
        let { classes } = this.props;
        let { langs } = this.state;
        let current = langs.options.filter((option: Object): boolean =>
            option.key === langs.current)[0] || langs.options[0];

        const openMenu = () => {
            this.setState((state: Object): Object => ({ langs: merge({}, langs, { open: !state.langs.open }) }));
        };

        const hideMenu = (event: Object) => {
            if (this.anchorEl.contains(event.target)) {
                return;
            }
            this.setState({ langs: merge({}, langs, { open: false }) });
        };
        const languageFlag: string = langs.options.find((lang: Object): Object => lang.key === langs.current).image;

        return (
            <Grid item>
                <Button
                    ref={(node: Object) => {
                        this.anchorEl = node;
                    }}
                    aria-owns={langs.open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={openMenu}
                    className={classes.languageSwitcher}
                    data-automation={'language-button'}
                >
                    <span style={{ display: 'flex' }}>
                        <LanguageFlag src={languageFlag} data-automation={'flag'}/>
                        <LanguageSelected>{current.name}</LanguageSelected>
                    </span>
                    <ArrowDropDown />
                </Button>
                <Popper
                    open={langs.open}
                    anchorEl={this.anchorEl}
                    style={{zIndex: 2}}
                    transition
                >
                    {({ TransitionProps, placement }: Object): Node => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{ transformOrigin: placement === 'bottom' ? 'center bottom' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={hideMenu}>
                                    <MenuList data-automation={'languages-menu-wrapper'} className={classes.languageList}>
                                        {langs.options.map((option: Object, i: number): Node => {
                                            return (
                                                <MenuItem
                                                    key={i}
                                                    onClick={(): void => this.changeLang(option.key)}
                                                    className={classes.languageItem}
                                                    data-automation={'language-item'}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Footer);
