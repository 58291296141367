// @flow
import type Node from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {CodeResponse} from '@react-oauth/google';
import {useDeviceInfo, useTranslate, useFeatureIsOn} from '../../../hooks';
import {
    DownloadDescription, DownloadSection, Header, HeaderSection, InfoIcon, ProgressBar, ProgressCounter, ProgressLoader, ProgressLoaderFlash,
    ProgressSection, WotLogo, HeaderSubtitle, RetryScan, Contact, ConnectWithGoogleBox
} from './StyledScanPage';
import {dispatchWotTraceIdConnect} from '../../../utils/wotTraceId';
import {ConnectWithGoogleButtonUi, ConnectWithGoogleTerms} from '../../ConnectWithGoogleHistoryButton/ConnectWithGoogleButton';
import {useConnectWithGoogleHistoryLogin} from '../../ConnectWithGoogleHistoryProvider';

type ScanPageProps = {
    googleCode?: string,
    onHistoryResultsReceived: () => void,
    retryOnError?: () => void,
    onConnectSuccessAfterNotFound?: (codeResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => void
}

const intervals = [0, 3, 15, 18, 25, 35, 50, 53, 60, 67, 69, 78, 82, 87, 90, 92, 96, 98, 99];

export const ScanPage = ({googleCode, onHistoryResultsReceived, retryOnError, onConnectSuccessAfterNotFound}: ScanPageProps ): Node => {
    const dispatch = useDispatch();
    const percentageProgress = useRef(0);
    const percentageRef = useRef(null);
    const barRef = useRef(null);
    const increaseTimeOut = useRef(null);
    const startScanTimeOut = useRef(null);
    const completeTimeOut = useRef(null);
    const webHistoryResults = useRef();
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const {isMobile, wotTraceId, wotTraceIdConnect} = useDeviceInfo();
    const isFullPageFlow = useFeatureIsOn('google-history-oauth-full-page-flow');
    const translate = useTranslate();
    const scanPageTranslate = (key: string): string => translate(`modals.webHistory.scan.${key}`);

    let fetchAbortController;
    try {
        fetchAbortController = new AbortController();
    } catch (e) {
        fetchAbortController = {};
    }

    const reLoginWithGoogle = useConnectWithGoogleHistoryLogin({
        onSuccess: onConnectSuccessAfterNotFound
    });

    const retryScanOnError = () => {
        if (googleCode || wotTraceIdConnect) {
            start(googleCode);
        } else {
            if (retryOnError) {
                retryOnError();
            } else {
                setIsError(false);
            }
        }
    };

    const clearTimeOuts = () => {
        clearTimeout(increaseTimeOut.current);
        clearTimeout(startScanTimeOut.current);
        clearTimeout(completeTimeOut.current);
    };

    const runUiScan = (interval: number = 0) => {
        if (!webHistoryResults.current && interval < intervals.length) {
            increaseProgress(intervals[interval]);
            if (interval + 1 < intervals.length) {
                startScanTimeOut.current = setTimeout(runUiScan, Math.ceil(3000 * Math.random()), interval + 1);
            }
        }
    };

    const increaseProgress = (to: number = 0) => {
        const target = Math.min(to, 100);
        if (percentageProgress.current < target) {
            updateUIProgress(++percentageProgress.current);
            continueUntilTarget(target);
            on100completeScan();
        }
    };

    const updateUIProgress = (value: number) => {
        const currentPercentage = `${value}%`;
        if (percentageRef.current && barRef.current) {
            percentageRef.current.innerText = currentPercentage;
            barRef.current.style.width = currentPercentage;
        }
    };

    const continueUntilTarget = (target: number) => {
        if (percentageProgress.current < target) {
            increaseTimeOut.current = setTimeout(increaseProgress, target === 100 ? 10: 40, target);
        }
    };

    const on100completeScan = () => {
        if (percentageProgress.current === 100) {
            if (isHistoryFound()) {
                completeTimeOut.current = setTimeout(() => {
                    onHistoryResultsReceived(webHistoryResults.current);
                }, 600);
            } else {
                setLoading(false);
            }
        }
    };

    const getWebHistory = async (gCode: string): Promise<void> => {
        runUiScan();
        try {
            const response = await fetch(`${location.origin}/get-history-score`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'wot-trace-id': wotTraceId
                },
                signal: fetchAbortController.signal,
                body: JSON.stringify({ googleCode: gCode, uuid: wotTraceId, redirectUri: isFullPageFlow && `${location.origin}/have-i-been-compromised` })
            });
            clearTimeOuts();
            const {reputation, totalHistory, status} = await response.json();
            if (response.status === 200) {
                webHistoryResults.current = {...reputation, totalHistory};
                increaseProgress(100);
            } else {
                if (response.status !== 500 && status !== 'unavailable') {
                    dispatchWotTraceIdConnect(false, dispatch);
                }
                setIsError(true);
                setLoading(false);
            }
        } catch (e) {
            if (e.message !== 'The user aborted a request.') {
                setIsError(true);
                setLoading(false);
                clearTimeOuts();
            }
        }
    };

    const start = (gCode: string) => {
        percentageProgress.current = 0;
        webHistoryResults.current = null;
        if (isError) {
            setIsError(false);
        }
        if (!loading) {
            setLoading(true);
        }
        getWebHistory(wotTraceIdConnect ? null: gCode);
    };

    const cleanupFunction = () => {
        fetchAbortController.abort?.();
        clearTimeOuts();
    };

    useEffect((): () => void => {
        start(googleCode);
        return cleanupFunction;
    }, []);

    const isHistoryFound = (): boolean => Boolean((webHistoryResults.current?.totalHistory || 0) > 0);

    const Title = (): Node => {
        const title = scanPageTranslate(`${loading ? '' : isError ? 'error-' : !isHistoryFound() ? 'notFound-' : !wotTraceIdConnect ? 'connect-' : ''}title`);
        return title ? <Header>{title}</Header> : null;
    };
    const Subtitle = (): Node => {
        const subTitle = loading ? null : scanPageTranslate(`${isError ? 'error' : !isHistoryFound() ? 'notFound' : !wotTraceIdConnect ? 'connect' : ''}-subtitle`);
        return subTitle ? <HeaderSubtitle>{subTitle}</HeaderSubtitle>: null;
    };

    const Content = (): Node => {
        switch (true) {
            case loading:
                return (
                    <ProgressSection>
                        <ProgressCounter ref={percentageRef}>0%</ProgressCounter>
                        <ProgressBar>
                            <ProgressLoader ref={barRef}>
                                <ProgressLoaderFlash/>
                            </ProgressLoader>
                        </ProgressBar>
                    </ProgressSection>
                );
            case isError:
                return <>
                    <RetryScan onClick={retryScanOnError}>{scanPageTranslate('retry')}</RetryScan>
                    <Contact>{scanPageTranslate('contact')}</Contact>
                </>;
            case !wotTraceIdConnect || !isHistoryFound():
                return (
                    <ConnectWithGoogleBox>
                        <ConnectWithGoogleButtonUi onClick={reLoginWithGoogle}/>
                        <ConnectWithGoogleTerms/>
                    </ConnectWithGoogleBox>
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            <HeaderSection>
                <WotLogo/>
                <Title/>
                <Subtitle/>
            </HeaderSection>
            <Content/>
            {loading && <DownloadSection isFullPageFlow={isFullPageFlow}>
                <InfoIcon/>
                <DownloadDescription>
                    {scanPageTranslate(`${isMobile ? 'mobile' : 'desktop'}Tip`)}
                </DownloadDescription>
            </DownloadSection>}
        </>
    );
};
