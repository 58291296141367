// @flow
import * as React from 'react';
import numeral from 'numeral';
import moment from 'moment';
export type ITranslate = (key: string, params?: {}) => string;
export type IPluralTranslate = (count: number, keys: string[], params?: {}) => string;
export type IGetNumberLocale = (number: number, formatParam?: string) => string;
export type IGetDateLocale = (date: string | number | {}, formatParam?: string) => string;
export type ILocalesTransformers = {
    translate: ITranslate,
    pluralTranslate: IPluralTranslate,
    getNumberLocale: IGetNumberLocale,
    getDateLocale: IGetDateLocale
};

const defaultLanguage = 'en';

function setExternalLibraryLocale(library, libraryLocale, checkIfLocaleIsAlreadyLoaded, loadLocale)
{
    if (libraryLocale)
    {
        if (!checkIfLocaleIsAlreadyLoaded(libraryLocale))
        {
            // Specific locale is not yet loaded, load it (required by external libraries)
            loadLocale(libraryLocale);
        }

        // Update library to use the locale
        library.locale(libraryLocale);
    }
    else
    {
        // Update library to use the default language
        library.locale(defaultLanguage);
    }
}

export const localesFactory = (locales): ILocalesTransformers => {

    // Update moment's and numeral locale
    setExternalLibraryLocale(numeral, locales.current.numeralLocale, (locale) => locale in numeral.locales, (locale) => require('numeral/locales/' + locale));
    setExternalLibraryLocale(moment, locales.current.momentLocale, (locale) => moment.locales().includes(locale), (locale) => require('moment/locale/' + locale));

    function translate (key, params) {
        let res = locales.current[key] || locales._default[key] || key;
        if (params) {
            Object.keys(params).forEach((param) => res = res.replace(new RegExp(`%${param}%`, 'g'), params[param]));
        }
        return res;
    }

    return {
        translate,
        pluralTranslate: function(count, keys, params) {
            if (isNaN(count) || count < 0)
            {
                throw new TypeError("Parameter 'count' is not a number!");
            }

            const key = count < keys.length ? keys[count] : keys[keys.length - 1];
            return translate(key, Object.assign({count}, params));
        },
        getNumberLocale: function(number, formatParam) {
            const format = formatParam || locales.current.formats.number;
            return numeral(number).format(format);
        },
        getDateLocale: function(date, formatParam) {
            const format = formatParam || locales.current.formats.date;
            return moment(date).format(format);
        }
    };
}


export const LocalesContext: any = React.createContext({});
export const LocalesProvider = LocalesContext.Provider;
export const LocalesConsumer = LocalesContext.Consumer;
