import styled from 'styled-components';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {FlexColumn, mobileBreakPoint} from '../../StyledUtils';
import {imgsPath} from '../../utils/const';
import {Contact} from './ScanPage/StyledScanPage';

export const ModalContent = styled(FlexColumn)`
    box-sizing: border-box;
    overflow: auto;
    max-width: 600px;
    position: relative;
    align-items: center;
    width: 600px;
    padding: 32px 48px;
    border-radius: 24px;
    color: #373B3E;
    background-image: url(${imgsPath}/bg-connect-with-google.svg);
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    ${Contact} {
        text-decoration: underline !important;
    }
    @media (max-width: ${mobileBreakPoint}){
        width: auto;
        margin: 16px;
        padding: 16px;
    }
`;

export const X = styled(CloseRoundedIcon)`
    position: absolute;
    top: 20px;
    right: 20px;
    color: #929CA5;
    cursor: pointer;
`;
